



































































































































































































































































































































































































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import PreviewDoc from '@/modules/accounting/components/PreviewDoc.vue';
import { AccountingData, CounterParty, Detail, DocumentItem } from '@/modules/accounting/store/types';
import SygniInput from "@/components/inputs/SygniInput.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniRadio from "@/components/inputs/SygniRadio.vue";
import SygniDatePicker from "@/components/inputs/SygniDatePicker.vue";
import SygniRangeInput from "@/components/inputs/SygniRangeInput.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import SygniToggleSwitch from "@/components/inputs/SygniToggleSwitch.vue";
import SygniTextArea from "@/components/inputs/SygniTextArea.vue";
import SygniModal from "@/components/layout/SygniModal.vue";
import { BACKEND_BASE_URL, ibanSupportedCountries, globalCurrencies } from "@/shared/consts";
import FileUploader from "@/components/FileUploader.vue";
import AttachmentsTable from '../components/AttachmentsTable.vue';
import Utils from '@/modules/shared/utils/utils';
import { iban } from '@/shared/validators';
import { BSpinner } from "bootstrap-vue";
import { SelectOption } from '@/store/types';
import { MD5 } from "@/shared/md5";
import { Watch } from 'vue-property-decorator';
import { helpers, required, requiredIf } from 'vuelidate/lib/validators';
import FlagsManagementModal from '../components/FlagsManagementModal.vue';
import FlagAccessModal from '../components/FlagAccessModal.vue';
import { mapState } from 'vuex';
import { create, all } from 'mathjs'
import _ from 'lodash';
const math = create(all);

Component.registerHooks(['validations'])
@Component({
  components: {
    FileUploader,
    PreviewDoc,
    SygniInput,
    SygniSelect,
    SygniDatePicker,
    SygniRangeInput,
    SygniContainerTitle,
    SygniCheckbox,
    SygniRadio,
    SygniRectButton,
    SygniRoundedButton,
    SygniToggleSwitch,
    SygniTextArea,
    SygniModal,
    BSpinner,
    FlagsManagementModal,
    FlagAccessModal,
    AttachmentsTable
  },
  computed: mapState('accounting', {
    documentsUploadProgress: (state: any) => state.documentsUploadProgress,
    attachmentsUploadProgress: (state: any) => state.attachmentsUploadProgress,
  })
})
export default class accountingDocumentModule extends Vue {
  readonly supportedFileFormats: Array<string> = ['pdf', 'jpeg', 'jpg', 'png', 'doc', 'docx'];
  documentsUploadProgress!: any;
  attachmentsUploadProgress!: any;
  areCounterPartyOptionsLoading: boolean = false;
  // disableBankAccountNumber: boolean = false;
  // disableSwiftCodeNumber: boolean = false;
  showBankAccountModal: boolean = false;
  isPaymentPackageLoading: boolean = false;
  selectedBankAccount: any = null;
  paymentForSingleDocument: any = false;
  isSepa: boolean = false;
  useCustomPaymentDate: boolean = false;
  customPaymentDate: string = '';
  customPackageName: string = '';
  counterPartySearchValue: string = '';
  counterPartyOptions: any = [];
  selectedDocument: string = '';
  document: any = null;
  isLoading: boolean = false;
  isRejectionModalLoading: boolean = false;
  isBookModalLoading: boolean = false;
  hasChanges: boolean = false;
  isExitConfirmationModalLoading: boolean = false;
  showExitConfirmationModal: boolean = false;
  showCancelModal: boolean = false;
  isCancelModalLoading: boolean = false;
  isReportModalLoading: boolean = false;
  showModal: boolean = false;
  showRejectionModal: boolean = false;
  showReportModal: boolean = false;
  isAttachmentsTableBusy: boolean = false;
  cantEdit: boolean = true;
  showFlagManagementModal: boolean = false;
  documentInitialised: boolean = false;
  showFlagAccessModal: boolean = false;
  showBookModal: boolean = false;
  sharingFlag: any = null;
  vats: Array<SelectOption<string>> = [
    { value: 'ZW', label: 'ZW' },
    { value: 'NP', label: 'NP' },
    { value: '0%', label: '0%' },
    { value: '4%', label: '4%' },
    { value: '5%', label: '5%' },
    { value: '8%', label: '8%' },
    { value: '23%', label: '23%' },
  ];
  formData: AccountingData = {
    documentType: {
      value: '',
    },
    documentNumber: {
      value: '',
    },
    cashBankReportCreated: {
      value: false
    },
    correctedDocumentNumber: {
      value: '',
    },
    issueDate: {
      value: '',
    },
    note: {
      value: '',
    },
    paymentDate: {
      value: '',
    },
    saleDate: {
      value: '',
    },
    additionDate: {
      value: '',
    },
    registerType: {
      value: '',
    },
    documentReceiptDate: {
      value: '',
    },
    paymentMethod: {
      value: '',
    },
    accountId: {
      value: '',
    },
    paymentStatus: {
      value: '',
    },
    prepaid: {
      value: false,
    },
    currency: {
      value: '',
    },
    bankAccount: {
      value: '',
    },
    bankAccountCountry: {
      value: '',
    },
    bankAccountSwift: {
      value: '',
    },
    counterPartyName: {
      value: '',
    },
    counterPartyTin: {
      value: '',
    },
    counterPartyTaxpayerStatus: {
      value: '',
    },
    counterPartyCountry: {
      value: '',
    },
    street: {
      value: '',
    },
    streetNumber: {
      value: '',
    },
    apartment: {
      value: '',
    },
    city: {
      value: '',
    },
    zipCode: {
      value: '',
    },
    cashMethod: {
      value: '',
    },
    glAccount: {
      value: '',
    },
    costCenter: {
      value: '',
    },
    description: {
      value: '',
    },
    details: [],
  };
  documentAttachments: Array<any> = [];
  actionType: 'close' | 'prev' | 'next' = 'close';
  sendToApprovalErrors: string[] = [];
  reportComment: string = null;
  whitelistResponse: any = null;
  changeGlComment?: string = null;
  isChangeGlModalLoading: boolean = false;
  showChangeGlModal: boolean = false;

  get bankAccounts() {
    return this.$store.getters['accounting/getBankAccounts'];
  }

  get formattedBankAccounts() {
    return this.bankAccounts?.map((el: any) => {
      return {
        label: `${el?.name} | ${el?.account}`,
        value: el?.account
      }
    }) || []
  }

  get defaultPaymentPackageName() {
    const bankAccount = this.selectedBankAccount ? this.selectedBankAccount : 'xxxxx';
    const date = new Date();
    return `Payment package from ${bankAccount}, ${date.getFullYear()}-${Utils.pad(date.getMonth() + 1, 2)}-${Utils.pad(date.getDate() + 1, 2)} ${Utils.pad(date.getHours(), 2)}:${Utils.pad(date.getMinutes(), 2)}:${Utils.pad(date.getSeconds(), 2)}`;
  }

  get selectedBankName(): string {
    const selectedBank = this.bankAccounts?.find((el: any) => el?.account == this.selectedBankAccount);

    if(selectedBank) {
      return selectedBank?.name;
    }

    return '';
  }

  get selectedBankCurrency(): string {
    const selectedBank = this.bankAccounts?.find((el: any) => el?.account == this.selectedBankAccount);

    if(selectedBank) {
      return selectedBank?.currency;
    }

    return '';
  }

  get selectedBankType(): string {
    const selectedBank = this.bankAccounts?.find((el: any) => el?.account == this.selectedBankAccount);

    if (selectedBank) {
      return selectedBank?.type;
    }

    return '';
  }

  get whitelistInfo() {
    if (this.formData?.bankAccountCountry?.value !== 'PL') {
      if (this.ibanSupportedCountries.includes(this.formData?.bankAccountCountry?.value)) {
        return {
          message: !this.$v?.formData?.bankAccount?.$invalid ? 'Correct IBAN' : 'Incorrect IBAN',
          checked: !this.$v?.formData?.bankAccount?.$invalid ? true : false
        }
      } else {
        return {
          message: '',
          checked: true,
        }
      }
    }

    let message: string = this.formData?.bankAccount?.value && this.$v?.formData?.bankAccount?.$invalid ? 'Incorrect IBAN' : ''
    let checked: boolean = false

    if (!message && this.formData?.bankAccount?.value) {
      if (!this.formData?.counterPartyTin?.value) {
        message = 'Not checked with Whitelist'
      } else {
        if (this.whitelistResponse) {
          if (this.whitelistResponse?.result?.accountAssigned === 'TAK') {
            message = 'Checked with Whitelist'
            checked = true
          }
  
          if (this.whitelistResponse?.result?.accountAssigned === 'NIE') {
            message = 'Not on the Whitelist'
          }
  
          if (this.whitelistResponse?.code) {
            switch (this.whitelistResponse?.code) {
              case 'WL-108':
              case 'WL-109':
              case 'WL-110':
              case 'WL-111':
                message = 'Incorrect IBAN'
                break;
              case 'WL-112':
              case 'WL-113':
              case 'WL-114':
              case 'WL-115':
                message = 'Incorrect Counterparty TIN'
                break;
              case 'WL-100':
              case 'WL-130':
              case 'WL-190':
              case 'WL-191':
              case 'WL-195':
              case 'WL-196':
                message = 'Data provider error'
                break;
              default:
                message = 'Not checked with Whitelist'
                break;
            }
          }
        }
      }
    }

    return {
      message,
      checked
    }
  }

  get isReportActionDisabled() {
    if (!this.reportComment) {
      return true
    }

    return this.reportComment?.replace(/ /g, '')?.length == 0
  }

  get isChangeGlActionDisabled() {
    if (!this.changeGlComment) {
      return true
    }

    return this.changeGlComment?.replace(/ /g, '')?.length == 0
  }

  get isUltimatelyFailed() {
    // return this.document?.errorMessage === 'Incorrect buyer tax number' && this.document?.status?.toLowerCase() === 'failed'
    return false
  }

  get dictionaries() {
    return this.$store.getters['accounting/getDictionaries'];
  }

  get paymentMethodValue() {
    return this.formData.paymentMethod.value;
  }

  get shouldSend() {
    return Utils.shouldSend(this.document, this.contextData, this.activeUserData)
  }
  
  get activeGlAccounts() {
    if(this.formData.registerType.value === 'revenues') {
      return this.dictionaries?.activeRevenuesGlAccounts;
    }

    if(this.formData.registerType.value === 'costs') {
      return this.dictionaries?.activeCostsGlAccounts;
    }

    return [];
  }

  get contextData() {
    return this.$store.getters['accounting/getContextData'];
  }

  get bankAccountDisplayFormat() {
    return this.ibanSupportedCountries.includes(this.formData.bankAccountCountry.value) ? 'iban' : false;
  }

  get bankAccountDisplayCheck() {
    return (this.ibanSupportedCountries.includes(this.formData.bankAccountCountry.value)) ? true : false;
  }

  get bankAccountSwiftDisplayCheck() {
    return helpers.regex(this.formData.bankAccountSwift.value, /^[a-zA-Z]{6}[a-zA-Z0-9]{2}([a-zA-Z0-9]{3})?$/);
  }

  get ibanSupportedCountries() {
    return ibanSupportedCountries;
  }

  get globalCurrencies() {
    return globalCurrencies.map((el: any) => {
      return { label: el, value: el };
    });
  }

  get tooltipStatusMessage() {
    if (this.document?.status == 'rejected') return this.document?.note ? this.document.note : '';

    return this.document?.status == 'failed' ? this.document?.errorMessage : '';
  }

  get isCorrectiveInvoice(): boolean {
    return this.formData?.documentType?.value?.toLowerCase() == 'corrective invoice' || this.formData?.documentType?.value?.toLowerCase() === 'corrective purchase invoice' || this.formData?.documentType?.value?.toLowerCase() === 'corrective sales invoice';
  }

  get accountingTableFiltersQuery() {
    return this.$store.getters['accounting/getDocumentsTableFiltersQuery'];
  }

  get filteredCounterPartyOptions() {
    if (this.counterPartySearchValue) {
      return this.counterPartyOptions.filter((el: any) => {
        if (el.label) {
          if (el.label.toLowerCase().includes(this.counterPartySearchValue.toLowerCase())) {
            return true;
          } else {
            if (el.data?.tin?.includes(this.counterPartySearchValue)) {
              return true;
            }
            return false;
          }
        } else {
          return false;
        }
      });
    }

    return this.counterPartyOptions;
  }

  get showDocument() {
    return this.selectedDocument;
  }

  get totalGross() {
    if (this.formData.details) {
      let tot = math.bignumber(0);
      this.formData.details.forEach((i: any) => {
        let v = math.bignumber((i?.gross?.value || 0)?.toString()?.replace(/\s/g, '').replace(/,/g, '.'));
        if (!v) {
          v = math.bignumber(0);
        }
        tot = math.bignumber(math.add(tot, v))
      });

      return this.getRoundedValue(math.number(tot).toString());
    }
    return 0;
  }

  get totalNet() {
    if (this.formData.details) {
      let tot = math.bignumber(0);
      this.formData.details.forEach((i: any) => {
        let v = math.bignumber((i?.net?.value || 0)?.toString()?.replace(/\s/g, '').replace(/,/g, '.'));
        if (!v) {
          v = math.bignumber(0);
        }
        tot = math.bignumber(math.add(tot, v))
      });

      return this.getRoundedValue(math.number(tot).toString());
    }
    return 0;
  }

  get totalVat() {
    if (this.formData.details) {
      let tot = math.bignumber(0);
      this.formData.details.forEach((i: any) => {
        let v = math.bignumber((i?.vat?.value || 0)?.toString()?.replace(/\s/g, '').replace(/,/g, '.'));
        if (!v) {
          v = math.bignumber(0);
        }
        tot = math.bignumber(math.add(tot, v))
      });

      return this.getRoundedValue(math.number(tot).toString());
    }
    return 0;
  }

  get totalZw() {
    return this.getTotal(this.formData.details.filter(v => v.vatPercent.value === 'ZW'));
  }

  get totalNp() {
    return this.getTotal(this.formData.details.filter(v => v.vatPercent.value === 'NP'));
  }

  get totalZero() {
    return this.getTotal(this.formData.details.filter(v => v.vatPercent.value === '0%'));
  }
  
  get totalFour() {
    return this.getTotal(this.formData.details.filter(v => v.vatPercent.value === '4%'));
  }

  get totalFive() {
    return this.getTotal(this.formData.details.filter(v => v.vatPercent.value === '5%'));
  }

  get totalEight() {
    return this.getTotal(this.formData.details.filter(v => v.vatPercent.value === '8%'));
  }

  get total23() {
    return this.getTotal(this.formData.details.filter(v => v.vatPercent.value === '23%'));
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  get cantEditPaymentStatus() {
    if (this.document?.actions?.includes('edit-payment-status') && (this.document?.status == 'approved' || this.document?.status == 'booked')) return false;

    return this.cantEdit || !this.document?.actions?.includes('edit-payment-status')
  }

  get vatRateOptions() {
    return this.dictionaries?.vatRates || []
  }

  get activeCostCenters() {
    const costCenter = this.dictionaries?.activeCostCenters.find((el: any) => el.value === this.formData.costCenter.value);

    if(!costCenter) {
      const missingCostCenter = this.dictionaries?.costCenters.find((el: any) => el.value === this.formData.costCenter.value);

      if(missingCostCenter) {
        return _.uniq(this.dictionaries?.activeCostCenters.concat([missingCostCenter]));
      }
    }

    return this.dictionaries?.activeCostCenters;
  }

  closeBankAccountModal() {
    this.showBankAccountModal = false;
    this.$v.$reset();
    this.selectedBankAccount = '';
  }

  handlePaste(event: any) {
    const paste = event.clipboardData?.getData('text') || (window as any)?.clipboardData.getData('text')
    this.formData.bankAccount.value = this.$options.filters.bankAccountNumber(paste)
  }

  openReportModal() {
    this.showReportModal = true
  }

  closeReportModal() {
    this.reportComment = null
    this.isReportModalLoading = false
    this.showReportModal = false
  }

  openChangeGlModal() {
    this.showChangeGlModal = true
  }

  closeChangeGlModal() {
    this.changeGlComment = null
    this.isChangeGlModalLoading = false
    this.showChangeGlModal = false
  }

  openCancelModal() {
    this.showCancelModal = true;
  }

  closeCancelModal() {
    this.showCancelModal = false;
  }

  closeBookModal() {
    this.showBookModal = false;
  }

  enableBankAccountModal() {
    this.showBankAccountModal = true;
  }

  async generatePaymentPackage() {
    this.$v?.$touch();

    if(!this.$v.$error) {
      this.isPaymentPackageLoading = true
      const packageName = this.customPackageName ? this.customPackageName : this.defaultPaymentPackageName;
      const paymentDate = this.useCustomPaymentDate ? `${this.customPaymentDate} 00:00:00` : null;

      if(this.document?.id) {
        try {
          await this.$store.dispatch('accounting/generatePackage', { ids: [this.document.id], bankAccount: this.selectedBankAccount, bankAccountType: this.selectedBankType, paymentDate: paymentDate, packageName: packageName, isSepa: this.isSepa });
          this.closeBankAccountModal();
        } catch(error) {
          const errorMessage = this.$options.filters.errorHandler(error);
          this.$notify({
            duration: 2500,
            type: 'error',
            title: 'Error',
            text: this.$t(errorMessage).toString()
          });
        }
      }

      this.isPaymentPackageLoading = false
    }
  }

  async requestGlChange() {
    this.isChangeGlModalLoading = true

    try {
      const payload: { documentId: string, messageInfo: { category: string, comment: string } } = {
        documentId: this.document?.id,
        messageInfo: {
          category: null,
          comment: this.changeGlComment,
        }
      }
      await this.$store.dispatch('accounting/requestGlChange', payload)
      this.closeChangeGlModal()
      this.$notify({
        duration: 3000,
        type: 'success',
        title: 'Success',
        text: 'Your request have been sent. Thank you for your feedback.'
      })
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }
    this.isChangeGlModalLoading = false
  }

  async reportDocument() {
    this.isReportModalLoading = true

    try {
      const payload: { documentId: string, messageInfo: { category: string, comment: string } } = {
        documentId: this.document?.id,
        messageInfo: {
          category: null,
          comment: this.reportComment
        }
      }
      await this.$store.dispatch('accounting/reportDocument', payload)
      this.closeReportModal()
      this.$notify({
        duration: 3000,
        type: 'success',
        title: 'Success',
        text: 'Your report have been sent. Thank you for your feedback.'
      })

    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }
    this.isReportModalLoading = false
  }

  async handleBookDocumentAction() {
    if(this.hasChanges) {
      this.showModal = true;
    } else {
      if (this.document?.actions.includes('change-cash-bank-report-created-flag')) {
        this.showBookModal = true;
      } else {
        await this.bookDocument();
      }
    }
  }

  async bookDocument() {
    if(!this.isLoading) {
      this.isBookModalLoading = true;
      this.isLoading = true;
      try {
        if(this.document?.actions.includes('change-cash-bank-report-created-flag')) {
          await this.$store.dispatch('accounting/changeCashBankReportCreatedFlag', this.document?.id);
        }

        await this.$store.dispatch('accounting/bookDocument', this.document?.id);
        await this.loadDocument();
        this.$notify({
          duration: 3000,
          type: 'success',
          title: 'Success',
          text: 'Document booked'
        })
      } catch (e) {
        const errorMessage = this.$options.filters.errorHandler(e);
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: this.$t(errorMessage).toString()
        })
      }
      this.isBookModalLoading = false;
      this.isLoading = false;
      this.closeBookModal();
    }
  }

  async synchronizeCounterparty() {
    if(!this.isLoading) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('accounting/synchronizeCounterparty', this.document?.id);
        await this.loadDocument();
        this.$notify({
          duration: 3000,
          type: 'success',
          title: 'Success',
          text: 'Counterparty synchronized'
        })
        this.isLoading = false;
      } catch(e) {
        const errorMessage = this.$options.filters.errorHandler(e);
        this.isLoading = false;
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: this.$t(errorMessage).toString()
        })
      }
    }
  }

  async cancelDocument() {
    if(!this.isLoading) {
      this.closeCancelModal();
      this.isLoading = true;
      try {
        await this.$store.dispatch('accounting/cancelDocument', this.document?.id);
        await this.loadDocument();
        this.$notify({
          duration: 3000,
          type: 'success',
          title: 'Success',
          text: 'Document cancelled'
        })
        this.isLoading = false;
      } catch (e) {
        const errorMessage = this.$options.filters.errorHandler(e);
        this.isLoading = false;
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: this.$t(errorMessage).toString()
        })
      }
    }
  }

  enableFlagAccessModal(flag: any) {
    this.sharingFlag = flag;
    this.showFlagAccessModal = true;
  }

  closeFlagAccessModal() {
    this.showFlagAccessModal = false;
    this.sharingFlag = null;
  }

  enableFlagManagementModal() {
    this.showFlagManagementModal = true;
  }

  closeFlagManagementModal() {
    this.showFlagManagementModal = false;
  }

  closeExitConfirmationModal() {
    this.showExitConfirmationModal = false;
  }

  enableExitConfirmationModal() {
    this.showExitConfirmationModal = true;
  }

  closeRejectionModal() {
    this.showRejectionModal = false;
    this.formData.note.value = '';
  }

  enableRejectionModal() {
    this.showRejectionModal = true;
  }

  overwriteBankAccount(e: any) {
    if (e) this.formData.bankAccount.value = e?.target.value;
    const containsCode = (/^[A-Za-z]+$/).test(this.formData.bankAccount.value?.substring(0, 1)) || (/^[A-Za-z]+$/).test(this.formData.bankAccount.value?.substring(1, 2));
    if (this.formData.bankAccount.value && this.ibanSupportedCountries.includes(this.formData.bankAccountCountry.value)) {
      if (!containsCode && this.formData.bankAccountCountry.value) {
        this.formData.bankAccount.value = `${this.formData.bankAccountCountry.value}${this.formData.bankAccount.value}`;
      }
      if (containsCode && this.formData.bankAccountCountry.value) {
        this.formData.bankAccount.value = `${this.formData.bankAccountCountry.value}${this.formData.bankAccount.value.slice(2, this.formData.bankAccount.value.length)}`
      }
    } else if(this.formData.bankAccount.value && !this.ibanSupportedCountries.includes(this.formData.bankAccountCountry.value)) {
      if (containsCode && this.formData.bankAccountCountry.value) {
        this.formData.bankAccount.value = this.formData.bankAccount.value.slice(2, this.formData.bankAccount.value.length);
      }
    }

    this.checkWhitelist(true)
  }

  overwriteBankAccountSwift() {
    this.formData.bankAccountSwift.value = this.formData.bankAccountSwift.value.toUpperCase();
  }

  handleBankAccountKeyEvent(e: string): void {
    this.hasChanges = true;
    this.formData.bankAccount.value = e.toLocaleUpperCase();
  }

  handleBankAccountSwiftEvent(): void {
    this.hasChanges = true;
  }

  handleGlAccountChange(value: string) {
    const oldOption: string = _.clone(this.formData?.glAccount?.value)
    this.formData.glAccount.value = value
    let glAccountName = this.activeGlAccounts?.find((el: any) => el?.value === oldOption)?.label
    glAccountName = glAccountName?.slice(glAccountName?.indexOf(" ") + 1)

    let newDescription = this.activeGlAccounts?.find((el: any) => el?.value === value)?.label
    newDescription = newDescription?.slice(newDescription?.indexOf(" ") + 1)

    const detailsToUpdate: any[] = this.formData?.details?.filter((el: any) => el?.description?.value === '' || el?.description?.value === glAccountName)

    detailsToUpdate?.forEach((detail: any) => {
      this.$set(detail, 'description', { value: newDescription })
    })

    this.handleFieldChange()
  }

  handleFieldChange(): void {
    this.hasChanges = true;
  }

  handleDocumentTypeChange(value: any): void {
    this.hasChanges = true;

    switch (value) {
      case 'Corrective purchase invoice':
      case 'Purchase invoice':
        this.formData.registerType.value = 'costs';
        break;
      case 'Corrective sales invoice':
      case 'Sales invoice':
        this.formData.registerType.value = 'revenues';
        break;
      case 'Accounting note':
        this.formData.registerType.value = 'costs';
        this.formData.details?.forEach((detail: any) => {
          this.$set(detail.vatPercent, 'value', 'NP')
          this.calculateTotals(detail)
        })
        break;
      default:
        this.formData.registerType.value = null;
        break;
    }
  }

  closeModal(): void {
    this.showModal = false;
  }

  removeDetail(index: number) {
    this.formData.details.splice(index, 1);
  }

  addDetail() {
    this.formData.details.push({
      moreDetails: false,
      description: {
        value: '',
      },
      vatPercent: {
        value: '',
      },
      net: {
        value: '',
      },
      vat: {
        value: '',
      },
      gross: {
        value: '',
      },
      glAccount: {
        value: '',
      },
      costCenter: {
        value: '',
      }
    });
  }

  filterCounterPartyOptions(search: string) {
    this.counterPartySearchValue = search;
  }

  closeDocument() {
    this.actionType = 'close';
    if (this.hasChanges) {
      this.enableExitConfirmationModal();
      return;
    }

    this.closeDocumentActions();
  }

  closeDocumentConfirmed() {
    this.closeDocumentActions();
  }

  exitConfirmationModalConfirmed() {
    if(this.actionType == 'close') {
      this.closeDocumentConfirmed();
    } else if(this.actionType == 'next') {
      this.nextDocument();
    } else if(this.actionType == 'prev') {
      this.prevDocument();
    }
    this.showExitConfirmationModal = false;
  }

  closeDocumentActions() {
    this.$store.commit('accounting/clearDocumentIndexes');
    this.$router.push(`${this.$route.path.substring(0, this.$route.path.lastIndexOf('/'))}`);
  }

  downloadDocument() {
    if (this.document == undefined) return;
    this.downloadFileByUrl(this.document);
  }

  downloadFileByUrl(file: any) {
    Utils.downloadFileByUrl(`${BACKEND_BASE_URL}${file.pdfFilePath}`, file.fileName);
  }

  async addFiles(files: Array<File>): Promise<void> {
    this.isAttachmentsTableBusy = true;
    const payload = { files };
    const requests: Array<any> = await this.$store.dispatch('accounting/uploadAttachments', { files: payload, id: this.$route.params.id });
    const response = [];

    for (const [key, value] of Object.entries(requests)) {
      response.push({ fileName: key, status: value.status, errors: value.status == 'rejected' ? value.data?.response?.data?.violations : [] });
    }

    await this.loadAttachments();

    this.isAttachmentsTableBusy = false;

    this.$store.commit('accounting/setAttachmentsUploadProgress', -1);
  }

  removeAttachment(id: string) {
    const attachmentIndex = this.documentAttachments.findIndex((obj) => obj.id === id);
    this.documentAttachments.splice(attachmentIndex, 1);
  }

  prevDocumentHandler() {
    this.actionType = 'prev';
    if (this.hasChanges) {
      this.enableExitConfirmationModal();
      return;
    }

    this.prevDocument();
  }

  async prevDocument() {
    const offset = this.$store.getters['accounting/prevDocumentIndex'];
    const doc = await this.$store.dispatch('accounting/getDocumentByOffset', {
      filtersQuery: this.accountingTableFiltersQuery,
      offset: offset
    });
    if (doc) {
      this.$store.commit('accounting/setDocumentIndexes', offset);
      this.$router.push(`${this.$route.path.substring(0, this.$route.path.lastIndexOf('/'))}/${doc.id}`);
      this.isLoading = true;

      this.$nextTick(() => {
        this.loadDocument();
      });
    }
  }

  nextDocumentHandler() {
    this.actionType = 'next';
    if (this.hasChanges) {
      this.enableExitConfirmationModal();
      return;
    }

    this.nextDocument();
  }

  async nextDocument(skip: boolean = false)  {
    let offset = this.$store.getters['accounting/nextDocumentIndex'];
    if (skip) offset--;
    const doc = await this.$store.dispatch('accounting/getDocumentByOffset', {
      filtersQuery: this.accountingTableFiltersQuery,
      offset: offset
    });
    if (doc) {
      this.$store.commit('accounting/setDocumentIndexes', offset);
      this.$router.push(`${this.$route.path.substring(0, this.$route.path.lastIndexOf('/'))}/${doc.id}`);
      this.isLoading = true;

      this.$nextTick(() => {
        this.loadDocument();
      });
    }
  }

  async loadDocumentData(documentItem: any) {
    this.document = documentItem;

    this.formData.documentType.value = documentItem.type;
    this.formData.documentNumber.value = documentItem.documentNumber;
    this.formData.cashBankReportCreated.value = documentItem.cashBankReportCreated;
    this.formData.correctedDocumentNumber.value = documentItem.correctedDocumentNumber;
    this.formData.issueDate.value = documentItem.issueDate;
    this.formData.note.value = documentItem.note;
    this.formData.paymentDate.value = documentItem.paymentDate;
    this.formData.saleDate.value = documentItem.saleDate;
    this.formData.additionDate.value = documentItem.additionDate;
    this.formData.documentReceiptDate.value = documentItem.documentReceiptDate === null ? documentItem.saleDate : documentItem.documentReceiptDate;
    this.formData.paymentMethod.value = documentItem.paymentMethod;
    this.formData.accountId.value = documentItem.accountingId;
    this.formData.paymentStatus.value = documentItem.paymentStatus;
    this.formData.currency.value = documentItem.currency;
    this.formData.bankAccount.value = documentItem.bankAccount || '';
    if(documentItem.bankAccountCountry != null) {
      this.formData.bankAccountCountry.value = documentItem.bankAccountCountry;
    } else {
      this.formData.bankAccountCountry.value = 'PL';
    }
    this.formData.bankAccountSwift.value = documentItem.bankAccountSwift === null ? '' : documentItem.bankAccountSwift;
    this.formData.counterPartyName.value = this.getCounterPartyNameValue(documentItem.counterPartyName);
    this.formData.counterPartyTin.value = documentItem.counterPartyTin;
    this.formData.counterPartyTaxpayerStatus.value = documentItem.counterPartyTaxpayerStatus;
    this.formData.counterPartyCountry.value = documentItem.counterPartyCountry;
    this.formData.street.value = documentItem.counterPartyStreet;
    this.formData.streetNumber.value = documentItem.counterPartyStreetNumber;
    this.formData.apartment.value = documentItem.counterPartyApartmentNumber;
    this.formData.city.value = documentItem.counterPartyCity;
    this.formData.zipCode.value = documentItem.counterPartyZipCode;
    this.formData.cashMethod.value = documentItem.cashMethod;
    this.formData.glAccount.value = documentItem.glAccount;
    this.formData.costCenter.value = documentItem.costCenter;
    this.formData.description.value = documentItem.description;
    this.formData.prepaid.value = documentItem?.prepaid;

    // details
    this.formData.details = [];

    documentItem.documentItems.forEach((item: any) => {
      this.formData.details.push({
        moreDetails: (item.glAccount || item.costCenter),
        description: {
          value: item.name,
        },
        vatPercent: {
          value: item.vatRate,
        },
        net: {
          value: this.$options.filters.numberFormat(item.totalPriceNet, 2),
        },
        vat: {
          value: this.$options.filters.numberFormat(item.totalVatValue, 2),
        },
        gross: {
          value: this.$options.filters.numberFormat(item.totalPriceGross, 2),
        },
        glAccount: {
          value: item.glAccount,
        },
        costCenter: {
          value: item.costCenter,
        }
      })
    });

    this.cantEdit = documentItem?.actions?.includes('edit') ? false : true;
    this.selectedDocument = `${BACKEND_BASE_URL}${this.document.pdfFilePath}`;
    this.setDisableBankAccountNumberValue();

    const sendToApprovalErrors = await this.$store.dispatch('accounting/validateSendToApproval', this.$route.params.id);
    this.sendToApprovalErrors = sendToApprovalErrors.errors ? sendToApprovalErrors.errors : [];

    this.$nextTick(() => {
      this.hasChanges = false;
    })
  }

  clearBankAccountNumberValue() {
    this.formData.bankAccount.value = '';
    this.formData.bankAccountCountry.value = '';
    this.formData.bankAccountSwift.value = '';
  }

  get disableBankAccountNumber() {
    if (this.formData?.prepaid?.value) {
      return true;
    }

    if (this.activeUserData.role.workflow.includes('admin') || this.activeUserData.role.workflow.includes('accountant')) {
      if (this.formData.paymentMethod.value != 'Bank Transfer') {
        return true;
      } else {
        return false;
      }
    } else {
      if (this.activeUserData.role.paymentManager === true) {
        if(this.formData.paymentMethod.value != 'Bank Transfer') {
          return true;
        } else {
          return false;
        }
      } else {
        return (this.formData.paymentMethod.value != 'Bank Transfer' || this.document?.status === 'approved' || this.document?.status === 'booked' || this.cantEdit) ? true : false;
      }
    }
  }

  get disableSwiftCodeNumber() {
    if (this.formData?.prepaid?.value) {
      return true;
    }
    
    if ((this.activeUserData.role.workflow.includes('admin') || this.activeUserData.role.workflow.includes('accountant')) && this.formData.bankAccountCountry.value !== 'PL') {
      if (this.formData.paymentMethod.value != 'Bank Transfer') {
        return true;
      } else {
        return false;
      }
    } else {
      if (this.activeUserData.role.paymentManager && this.formData.bankAccountCountry.value !== 'PL') {
        if (this.formData.paymentMethod.value != 'Bank Transfer') {
          return true;
        } else {
          return false;
        }
      } else {
        return (this.formData.paymentMethod.value != 'Bank Transfer' || this.formData.bankAccountCountry.value === 'PL' || this.document?.status === 'approved' || this.document?.status === 'booked' || this.cantEdit) ? true : false;
      }
    }
  }

  setDisableBankAccountNumberValue(): void {
    // this.disableBankAccountNumber = this.bankAccountNumberDisabled;
    // this.disableSwiftCodeNumber = this.swiftCodeNumberDisabled;


    // if(!(this.activeUserData.role.workflow.includes('admin') || this.activeUserData.role.workflow.includes('accountant')) || this.cantEdit) {
    //   if(this.activeUserData.role.paymentManager === true) {
    //     this.disableBankAccountNumber = (this.formData.paymentMethod.value != 'Bank Transfer' || this.document?.status === 'approved' || this.document?.status === 'booked') ? true : false;
    //     this.disableSwiftCodeNumber = (this.formData.paymentMethod.value != 'Bank Transfer' || this.formData.bankAccountCountry.value === 'PL' || this.document?.status === 'approved' || this.document?.status === 'booked') ? true : false;
    //   } else {
    //     this.disableBankAccountNumber = true;
    //     this.disableSwiftCodeNumber = true;
    //   }
    // } else {
    //   this.disableBankAccountNumber = (this.formData.paymentMethod.value != 'Bank Transfer' || this.document?.status === 'approved' || this.document?.status === 'booked') ? true : false;
    //   this.disableSwiftCodeNumber = (this.formData.paymentMethod.value != 'Bank Transfer' || this.formData.bankAccountCountry.value === 'PL' || this.document?.status === 'approved' || this.document?.status === 'booked') ? true : false;
    // }
  }

  getRoundedValue(value: any) {
    let roundTo = 2

    const decimals = value.split('.')[1];

    const roundAvailable = decimals !== undefined && decimals.length > 2;
    if (roundAvailable) {
      const roundBy = decimals[2];

      if (roundBy > 4) {
        value = Number(value).toFixed(2).toString()
      }
    }

    if (value === '') {
      return value;
    }

    value = value.replace(/,/g, '.');

    value = value.replace(/ /g, '');
    value = value.replace(/ /g, '');
    value = Number(value);

    if (!value) {
      value = 0;
    }

    const dotIndex = value.toString().indexOf('.');

    if (dotIndex === -1) {
      value = Number(value).toFixed(roundTo);
    } else {
      value = value.toPrecision(dotIndex + 1 + roundTo);
      value = value.toString().substring(0, dotIndex + roundTo + 1);

      const decimalsLength = value.split('.')[1].length;

      if (decimalsLength < roundTo) {
        const fillAmount = roundTo - decimalsLength;
        value += '0'.repeat(fillAmount);
      }
    }

    value = value.replace(/\./g, ',');

    let splitted = value.split('.');
    splitted[0] = splitted[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return splitted.join('.');
  }

  getCounterPartyTaxPayerStatusText(status: string) {
    switch (status) {
      case('active'):
        status = 'Active';
        break;
      case('not_active'):
        status = 'Not active';
        break;
      default:
        status = this.$options.filters.snakeCaseToTitleCase(status)
        break;
    }

    return status;
  }

  getCounterPartyTaxPayerStatusClass(status: string) {
    let className = 'danger';
    switch (status) {
      case('active'):
        className = 'primary';
        break;
      default:
        break;
    }

    return className;
  }

  calculateTotals(detail: Detail) {
    this.hasChanges = true;
    const vatPercent = detail.vatPercent.value;
    let vat = 0;

    switch (vatPercent) {
      case('4%'):
        vat = 0.04;
        break;
      case('5%'):
        vat = 0.05;
        break;
      case('8%'):
        vat = 0.08;
        break;
      case('23%'):
        vat = 0.23;
        break;
    }

    const netValue = parseFloat(detail.net.value.toString().replace(/\s/g, '').replace(/,/g, '.'));
    const vatValue = this.getRoundedValue((netValue * vat).toString());
    const grossValue = this.getRoundedValue((netValue + (netValue * vat)).toString());

    detail.vat.value = vatValue;
    detail.gross.value = grossValue;
  }

  calculateGross(detail: Detail) {
    this.hasChanges = true;
    const netValue = parseFloat(detail.net.value.toString().replace(/\s/g, '').replace(/,/g, '.'));
    const vatValue = parseFloat(detail.vat.value.toString().replace(/\s/g, '').replace(/,/g, '.'));

    detail.gross.value = this.getRoundedValue((netValue + vatValue).toString());
  }

  getDocumentStatusClassName(status: string): string {
    let className = 'text-primary';
    switch (status?.toLowerCase()) {
      case('uploaded'):
      case('processing'):
      case('approved'):
      className = 'text-primary';
      break;
      case('processed'):
        className = 'text-default';
        break;
      case('sent-to-approval'):
      case('booking'):
        className = 'text-orange';
        break;
      case('booked'):
        className = 'text-success';
        break;
      case('failed'):
      case('rejected'):
      case('rejected-by-operator'):
      case('cancelled'):
      case('canceled'):
        className = 'text-danger';
        break;
      default:
        className = 'text-primary';
    }

    return className;
  }

  statusText(status: string): string {
    switch (status) {
      case('approved'):
        return 'Approved';
      case('processing'):
        return 'Processing';
      case('uploaded'):
        return 'Uploaded';
      case('processed'):
        return 'Processed';
      case('sent-to-approval'):
        return 'Sent for approval';
      case('rejected'):
        return 'Rejected';
      case('failed'):
        return 'Failed';
      case('booked'): 
        return 'Booked';
      case('canceled'):
        return 'Canceled';
    }
    return status;
  }

  mapRegisterType(types: Record<string, string>) {
    const type = this.formData.documentType.value;
    let registerType = '';

    switch (type) {
      case 'Corrective purchase invoice':
      case 'CORRECTIVE_PURCHASE_INVOICE':
        registerType = types['Corrective purchase invoice'];
        break;  
      case 'Purchase invoice':
      case 'PURCHASE_INVOICE':
        registerType = types['Purchase invoice'];
        break;
      case 'Corrective sales invoice':
      case 'CORRECTIVE_SALES_INVOICE':
        registerType = types['Corrective sales invoice'];
        break;
      case 'Sales invoice':
      case 'SALES_INVOICE':
        registerType = types['Sales invoice'];
        break;
      case 'Accounting note':
      case 'Accounting Note':
      case 'ACCOUNTING_NOTE':
        registerType = 'costs';
        break;
      default:
        break;
    }
    this.formData.registerType.value = registerType;
    this.mapDocumentType();
  }

  mapDocumentType() {
    const valueToMap = this.dictionaries.type.find((el: any) => el.label === this.formData.documentType.value);
    if(valueToMap) {
      this.formData.documentType.value = valueToMap.value;
    }
  }

  createDocumentObject() {
    this.$v.$touch();
    let documentItems: Array<any> = [];
      
    if (this.formData.bankAccount.value && this.ibanSupportedCountries.includes(this.formData.bankAccountCountry.value)) {
      const containsCode = (/^[A-Za-z]+$/).test(this.formData.bankAccount.value?.substring(0, 1)) || (/^[A-Za-z]+$/).test(this.formData.bankAccount.value?.substring(1, 2));
      if (!this.disableBankAccountNumber || !containsCode) {
        this.formData.bankAccount.value = (!containsCode) && this.formData.bankAccountCountry.value ? `${this.formData.bankAccountCountry.value}${this.formData.bankAccount.value}` : this.formData.bankAccount.value;
      }
    }

    this.$nextTick(() => {
      if ((this.$refs.scrollableColumn as HTMLDivElement).querySelectorAll('.error')[0]) {
        (this.$refs.scrollableColumn as HTMLDivElement).scrollTo({
          top: ((this.$refs.scrollableColumn as HTMLDivElement).querySelectorAll('.error')[0] as HTMLDivElement).offsetTop,
          behavior: 'smooth'
        });
      }
    })

    const glAccount = (this.dictionaries.glAccounts != undefined) ? this.dictionaries.glAccounts.find((el: SelectOption<string>) => el.value == this.formData.glAccount.value) : null;
    const costCenter = (this.dictionaries.costCenters != undefined) ? this.dictionaries.costCenters.find((el: SelectOption<string>) => el.value == this.formData.costCenter.value) : null;

    this.formData.details.forEach((detail: Detail) => {
      const grossValue = parseFloat(detail.gross.value.toString().replace(/\s/g, '').replace(/,/g, '.'));
      const netValue = parseFloat(detail.net.value.toString().replace(/\s/g, '').replace(/,/g, '.'));
      const vatValue = parseFloat(detail.vat.value.toString().replace(/\s/g, '').replace(/,/g, '.'));

      const glAccount = (this.dictionaries.glAccounts != undefined) ? this.dictionaries.glAccounts.find((el: SelectOption<string>) => el.value == detail.glAccount.value) : null;
      const costCenter = (this.dictionaries.costCenters != undefined) ? this.dictionaries.costCenters.find((el: SelectOption<string>) => el.value == detail.costCenter.value) : null;

      documentItems.push({
        costCenter: costCenter ? costCenter.value : null,
        costCenterLabel: costCenter ? costCenter.label : null,
        count: 1,
        glAccount: glAccount ? glAccount.value : null,
        glAccountLabel: glAccount ? glAccount.label : null,
        name: detail.description.value,
        totalPriceGross: grossValue,
        totalPriceNet: netValue,
        totalVatValue: vatValue,
        unitPriceGross: grossValue,
        unitPriceNet: netValue,
        vatRate: detail.vatPercent.value,
      });
    });

    const document: any = {
      accountingId: this.formData.accountId.value,
      actions: this.document.actions,
      additionDate: this.formData.additionDate.value,
      documentReceiptDate: this.formData.documentReceiptDate.value,
      bankAccount: this.formData.bankAccount.value,
      bankAccountCountry: this.formData.bankAccountCountry.value,
      bankAccountSwift: this.formData.bankAccountSwift.value,
      cashBankReportCreated: this.formData.cashBankReportCreated.value,
      cashMethod: this.formData.cashMethod.value,
      costCenter: costCenter ? costCenter.value : null,
      costCenterLabel: costCenter ? costCenter.label : null,
      counterPartyApartmentNumber: this.formData.apartment.value,
      counterPartyCity: this.formData.city.value,
      counterPartyName: this.getCounterPartyNameLabel(this.formData.counterPartyName.value),
      counterPartyStreet: this.formData.street.value,
      counterPartyStreetNumber: this.formData.streetNumber.value,
      counterPartyTaxpayerStatus: this.formData.counterPartyTaxpayerStatus.value,
      counterPartyTin: this.formData.counterPartyTin.value,
      counterPartyZipCode: this.formData.zipCode.value,
      counterPartyCountry: this.formData.counterPartyCountry.value,
      currency: this.formData.currency.value,
      description: this.formData.description.value,
      documentNumber: this.formData.documentNumber.value?.trim(),
      correctedDocumentNumber: this.formData.correctedDocumentNumber.value,
      fileName: this.document.fileName,
      filePath: this.document.filePath,
      glAccount: glAccount ? glAccount.value : null,
      glAccountLabel: glAccount ? glAccount.label : null,
      id: this.document.id,
      issueDate: this.formData.issueDate.value,
      legalEntityId: this.document.legalEntityId,
      paymentDate: this.formData.paymentDate.value,
      paymentMethod: this.formData.paymentMethod.value,
      paymentStatus: this.formData.paymentStatus.value,
      prepaid: this.formData.prepaid.value,
      pdfFilePath: this.document.pdfFilePath,
      saleDate: this.formData.saleDate.value,
      status: this.document.status,
      type: this.formData.documentType.value,
      documentItems: documentItems,
    };

    return document;
  }

  async getDocument() {
    try {
      const documentItem = await this.$store.dispatch('accounting/getDocument', this.$route.params.id);
      return documentItem;
    } catch(e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.isLoading = false;
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });
      this.$router.push({ name: 'accounting' });
    }
  }

  async approveDocument() {
    if (this.document == undefined) return;

    this.isLoading = true;
    await this.$store.dispatch('accounting/setApproval', this.document.id);
    await this.loadDocument();
    await this.nextDocument();
  }

  async rejectDocument() {
    if (this.document == undefined) return;

    if (this.formData.note.value) {
      this.isLoading = true;
      this.isRejectionModalLoading = true;
      await this.$store.dispatch('accounting/setReject', { ids: this.document.id, note: this.formData.note.value });
      this.isRejectionModalLoading = false;
      this.closeRejectionModal();
      await this.loadDocument();
      await this.nextDocument(true);
    } else {
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Validation error',
        text: 'You must enter the reason of document rejection'
      });
    }
  }

  async updatePaymentStatus() {
    if (this.formData.paymentStatus.value) {
      try {
        this.hasChanges = false;
        this.isLoading = true;
        await this.$store.dispatch('accounting/updatePaymentStatus', {
          documentId: this.document.id,
          status: this.formData.paymentStatus.value
        });
        this.isLoading = false;
        this.$notify({
          duration: 2500,
          type: 'success',
          title: 'Success',
          text: 'Changes saved.'
        });
      } catch (e) {
        const errorMessage = this.$options.filters.errorHandler(e);
        this.isLoading = false;
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: this.$t(errorMessage).toString()
        })
      }
    } else {
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: 'Payment status cannot be empty'
      })
    }
  }

  async confirmSaveChanges() {
    await this.saveChanges();
  }

  async updateDescription() {
    try {
      this.hasChanges = false;
      this.isLoading = true;
      await this.$store.dispatch('accounting/changeDescription', {
        documentId: this.document.id,
        description: this.formData.description.value
      })
      this.isLoading = false;
      if (!this.document?.actions?.includes('edit-payment-status')) {
        this.$notify({
          duration: 2500,
          type: 'success',
          title: 'Success',
          text: 'Changes saved.'
        });
      }
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.isLoading = false;
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      })
    }
  }

  async saveChanges() {
    const documentObject = this.createDocumentObject();

    if ((this.document?.status == 'approved' || this.document?.status == 'booked' || this.document?.status === 'sent-to-approval') && !(this.activeUserData?.role?.workflow.includes('admin') || this.activeUserData?.role?.workflow.includes('accountant'))) {
      if (this.document?.actions?.includes('update-description') && this.hasChanges) {
        await this.updateDescription();
      }
      
      if (this.document?.actions?.includes('edit-payment-status')) {
        await this.updatePaymentStatus();
      }

      return;
    }

    const counterParty: CounterParty = this.createCounterPartyObject(documentObject);
    await this.$store.dispatch('accounting/postCounterParty', counterParty);

    if (!this.$v.$error) {
      this.isLoading = true;
      await this.loadCounterPartyOptions('');

      try {
        const documentItem = await this.$store.dispatch('accounting/updateDocument', documentObject);
        await this.loadDocumentData(documentItem);
        this.$notify({
          duration: 2500,
          type: 'success',
          title: 'Success',
          text: 'Changes saved.'
        });
      } catch (error: any) {
        const errorMessage = this.$options.filters.errorHandler(error);
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: errorMessage
        });
      }

      this.isLoading = false;
      this.showModal = false;
    }
  }

  createCounterPartyObject(documentObject: any): CounterParty {
    return {
      name: documentObject.counterPartyName ? documentObject.counterPartyName.toString() : null,
      tin: documentObject.counterPartyTin ? documentObject.counterPartyTin.toString() : null,
      address: {
        street: documentObject.counterPartyStreet ? documentObject.counterPartyStreet.toString() : null,
        houseNumber: documentObject.counterPartyStreetNumber ? documentObject.counterPartyStreetNumber.toString() : null,
        flatNumber: documentObject.counterPartyApartmentNumber ? documentObject.counterPartyApartmentNumber.toString() : null,
        city: documentObject.counterPartyCity ? documentObject.counterPartyCity.toString() : null,
        zipCode: documentObject.counterPartyZipCode ? documentObject.counterPartyZipCode.toString() : null,
        country: documentObject.counterPartyCountry ? documentObject.counterPartyCountry.toString() : null,
      },
      taxPayerStatus: documentObject.counterPartyTaxpayerStatus ? documentObject.counterPartyTaxpayerStatus.toString() : null,
    }
  }

  async loadAttachments() {
    const attachments = await this.$store.dispatch('accounting/getDocumentAttachments', this.$route.params.id);
    
    this.documentAttachments = attachments ? attachments : [];
  }

  async loadDocument() {
    this.cantEdit = true;
    const documentItem = await this.getDocument();
    await this.loadAttachments();

    await this.loadDocumentData(documentItem);
    (this.$refs?.bankAccountSygniInput as SygniInput)?.setInputMask();
    this.overwriteBankAccount(false);

    this.isLoading = false;
  }

  changeFlagAction(flag: string) {
    if(this.document?.flags.includes(flag)) {
      const index = this.document?.flags.findIndex((el: string) => el == flag);
      if(index >= 0) {
        this.document?.flags.splice(index, 1);
      }
    } else {
      this.document?.flags.push(flag);
    }
  }

  async loadAllDocuments() {
    await this.$store.dispatch('accounting/getAllDocuments');
    const documents = this.$store.getters['accounting/paginationDataItems'];

    const index = documents.findIndex((document: DocumentItem) => {
      return document.id == this.$route.params.id;
    });

    this.$store.commit('accounting/setDocumentIndexes', index);
  }

  async deleteDocument() {
    // delete document
    this.isLoading = true;
    await this.$store.dispatch('accounting/deleteDocuments', [this.document?.id]);
    await this.$router.push({ path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/accounting` });
    // this.nextDocument(true);
  }

  async sendDocument() {
    if (this.document == undefined) return;

    const swiftCodeExist = this.formData.bankAccountSwift.value?.length === 8 || this.formData.bankAccountSwift.value?.length === 11;
    const countrySwiftRegulation = !this.formData.bankAccount.value?.slice(0, 2).includes('PL');

    if (!this.formData?.prepaid?.value && this.formData.paymentMethod.value === 'Bank Transfer' && countrySwiftRegulation && !swiftCodeExist) {
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: 'You must enter the SWIFT CODE'
      });
      return;
    }

    if (this.hasChanges) {
      this.showModal = true;
      return;
    }

    this.isLoading = true;

    const documentObject = this.createDocumentObject();
    const counterParty: CounterParty = this.createCounterPartyObject(documentObject);

    await this.$store.dispatch('accounting/postCounterParty', counterParty);

    try {
      await this.$store.dispatch('accounting/sendDocumentToApproval', this.document);
      await this.loadDocument();
      await this.nextDocument();
    } catch (error: any) {
      const errorMessage = this.$options.filters.errorHandler(error);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      });
    }
  }

  async loadCounterPartyOptions(search: string) {
    if(search) this.formData.counterPartyName.value = search;
    this.areCounterPartyOptionsLoading = true;
    const counterParties = await this.$store.dispatch('accounting/getCounterParties', search);

    this.counterPartyOptions = [];
    counterParties.forEach((counterParty: any) => {
      this.counterPartyOptions.push({
        label: counterParty.name,
        value: this.createCounterPartyValueString(counterParty),
        data: counterParty
      });
    })

    this.$nextTick(() => {
      this.areCounterPartyOptionsLoading = false;
    });
  }

  async loadRegisterType() {
    const { data } = await this.$store.dispatch('accounting/getDocumentRegisterMapper');

    this.mapRegisterType(data);
  }

  getCounterPartyNameLabel(value: string): string {
    const option = this.counterPartyOptions.find((el: any) => el.value == value);

    if(!option) {
      return this.formData.counterPartyName.value ? this.formData.counterPartyName.value : '';
    }

    return option?.label ? option.label : '';
  }

  getCounterPartyNameValue(label: string): string {
    const option = this.counterPartyOptions.find((el: any) => el.label == label);

    return option?.value ? option.value : label;
  }

  createCounterPartyValueString(counterParty: any) {
    let string = `${counterParty.name} - ${counterParty.address.street} ${counterParty.address.houseNumber}/${counterParty.address.flatNumber}, ${counterParty.address.zipCode} ${counterParty.address.city} ${counterParty.address.country} | ${counterParty.tin}`;

    return MD5(string);
  }

  addCounterPartyOption(option: any) {
    this.counterPartyOptions.push(option);
    this.formData.counterPartyName.value = option.value;
    this.hasChanges = true;
  }

  loadSelectedCounterParty(counterParty: any) {
    if(counterParty) {
      const counterPartyObject = this.counterPartyOptions.find((el: any) => el.value == counterParty);
  
      if (counterPartyObject?.data) {
        this.formData.counterPartyTin.value = counterPartyObject.data.tin;
        this.formData.counterPartyTaxpayerStatus.value = counterPartyObject.data.taxPayerStatus;
        this.formData.street.value = counterPartyObject.data.address?.street;
        this.formData.streetNumber.value = counterPartyObject.data.address?.houseNumber;
        this.formData.apartment.value = counterPartyObject.data.address?.flatNumber;
        this.formData.city.value = counterPartyObject.data.address?.city;
        this.formData.zipCode.value = counterPartyObject.data.address?.zipCode;
        this.formData.counterPartyCountry.value = counterPartyObject.data.address?.country;
      } else {
        this.formData.counterPartyTin.value = '';
        this.formData.counterPartyTaxpayerStatus.value = '';
        this.formData.street.value = '';
        this.formData.streetNumber.value = '';
        this.formData.apartment.value = '';
        this.formData.city.value = '';
        this.formData.zipCode.value = '';
        this.formData.counterPartyCountry.value = '';
      }
    }
  }

  getTotal(value: any) {
    if (value.length) {
      let totPg = math.bignumber(0);
      let totPn = math.bignumber(0);
      let totVat = math.bignumber(0);
      value.forEach((i: any) => {
        let totalPriceGross = math.bignumber(parseFloat(i.gross.value?.toString()?.replace(/\s/g, '').replace(/,/g, '.')));
        let totalPriceNet = math.bignumber(parseFloat(i.net.value?.toString()?.replace(/\s/g, '').replace(/,/g, '.')));
        let totalVatValue = math.bignumber(parseFloat(i.vat.value?.toString()?.replace(/\s/g, '').replace(/,/g, '.')));

        if (!totalPriceGross) {
          totalPriceGross = math.bignumber(0);
        }

        if (!totalVatValue) {
          totalVatValue = math.bignumber(0);
        }

        if (!totalPriceNet) {
          totalPriceNet = math.bignumber(0);
        }

        const pgValue = parseFloat(totalPriceGross.toString().replace(/\s/g, '').replace(/,/g, '.'));
        const pnValue = parseFloat(totalPriceNet.toString().replace(/\s/g, '').replace(/,/g, '.'));
        const vatValue = parseFloat(totalVatValue.toString().replace(/\s/g, '').replace(/,/g, '.'));

        totPg = math.bignumber(math.add(totPg, math.bignumber(pgValue)))
        totPn = math.bignumber(math.add(totPn, math.bignumber(pnValue)))
        totVat = math.bignumber(math.add(totVat, math.bignumber(vatValue)))
      });

      return {
        gross: this.getRoundedValue(math.number(totPg).toString()),
        net: this.getRoundedValue(math.number(totPn).toString()),
        vat: this.getRoundedValue(math.number(totVat).toString()),
      }
    }

    return null;
  }

  @Watch('formData.prepaid.value') onPrepaidValueChange() {
    this.clearBankAccountNumberValue();

    if (this.formData?.paymentMethod?.value === 'Bank Transfer' && !this.formData?.prepaid?.value) {
      this.formData.bankAccountCountry.value = 'PL'
    }
  }

  @Watch('formData.paymentMethod.value') onPaymentMethodValueChange() {
    this.$v.$reset();
    if (this.documentInitialised && this.formData.paymentMethod.value && this.formData.paymentMethod.value !== 'Bank Transfer') {
      this.formData.prepaid.value = false;
      this.clearBankAccountNumberValue();
    }

    if (this.formData?.paymentMethod?.value === 'Bank Transfer') {
      this.formData.bankAccountCountry.value = 'PL'
    }

    this.setDisableBankAccountNumberValue();
  }

  @Watch('formData.bankAccountCountry.value') onCounterPartyCountryChange() {
    this.$v.$reset();
    this.overwriteBankAccount(false);
  }

  async checkWhitelist(showLoading: boolean = false) {
    if (showLoading) {
      this.isLoading = true
    }

    if (this.formData?.counterPartyTin?.value && this.formData?.bankAccount?.value) {
      try {
        const first2Chars: any = this.formData?.counterPartyTin?.value?.slice(0, 2)
        let counterPartyTin = this.formData?.counterPartyTin?.value

        if (isNaN(first2Chars[0]) && isNaN(first2Chars[1])) {
          counterPartyTin = counterPartyTin?.replace(/\D/g,'')
        }

        this.whitelistResponse = await this.$store.dispatch('accounting/checkBankAccount', { nip: counterPartyTin, bankAccount: this.formData.bankAccount.value })
      } catch (e: any) {
        this.whitelistResponse = e?.response?.data
      }
    }

    if (showLoading) {
      this.isLoading = false
    }
  }

  async beforeMount() {
    this.isLoading = true;

    await this.$store.dispatch('genprox/getFlags');
    this.$store.dispatch('accounting/getBankAccounts');

    if (this.activeUserData.context.context == 'company' || this.activeUserData.context.context == 'fund' || this.activeUserData.context.context == 'fund-manager') {
      await this.$store.dispatch('accounting/getDictionaries');
      await this.loadCounterPartyOptions('');
    }

    const accountingPaginationDataSelectedDocument = localStorage.getItem('accountingPaginationDataSelectedDocument')

    this.$store.commit('accounting/setDocumentIndexes', localStorage.getItem('accountingPaginationDataActiveIndex'));
    if (accountingPaginationDataSelectedDocument !== null) {
      this.$store.commit('accounting/setSelectedDocument', accountingPaginationDataSelectedDocument);
    }
    await this.loadDocument();
    await this.checkWhitelist();
    this.documentInitialised = true;
    if(!this.formData.registerType.value) {
      await this.loadRegisterType();
    }
  }

  validations() {
    if (this.showBankAccountModal) {
      return {
        selectedBankAccount: { required },
        customPaymentDate: { required: requiredIf(() => this.useCustomPaymentDate) }
      }
    }

    const swiftCodeRequired = !this.formData.bankAccount.value?.toLowerCase().slice(0, 2).includes('pl');
    const swiftValidation = this.disableSwiftCodeNumber ? {} : swiftCodeRequired && !this.disableBankAccountNumber ? {
      required,
      valid: helpers.regex(this.formData.bankAccountSwift.value, /^[a-zA-Z]{6}[a-zA-Z0-9]{2}([a-zA-Z0-9]{3})?$/),
    } : {
      valid: helpers.regex(this.formData.bankAccountSwift.value, /^[a-zA-Z]{6}[a-zA-Z0-9]{2}([a-zA-Z0-9]{3})?$/),
    };

    if(this.paymentMethodValue === 'Bank Transfer' && !this.formData?.prepaid?.value) {
      if (this.ibanSupportedCountries.includes(this.formData.bankAccountCountry.value)) {
        if (!this.disableBankAccountNumber) {
          return {
            formData: {
              bankAccount: {
                value: iban(this.formData.bankAccountCountry.value)
              },
              bankAccountCountry: {
                value: { required },
              },
              bankAccountSwift: {
                value: swiftValidation
              },
            }
          }
        } else {
          return {
            formData: {
              bankAccount: {
                value: { required }
              },
              bankAccountCountry: {
                value: { required },
              },
              bankAccountSwift: {
                value: swiftValidation
              }
            }
          }
        }
      } else {
        return {
          formData: {
            bankAccount: {
              value: { required }
            },
            bankAccountCountry: {
              value: { required },
            },
            bankAccountSwift: {
              value: swiftValidation
            },
          }
        }
      }
    } else {
      return {
        formData: {
          bankAccount: {
            value: {},
          },
          bankAccountCountry: {
            value: {},
          },
          bankAccountSwift: {
            value: {},
          },
        }
      }
    }
  }
}
