var render = function () {
  var _vm$document, _vm$document2, _vm$document3, _vm$document4, _vm$document5, _vm$document6, _vm$document7, _vm$document8, _vm$document9, _vm$document10, _vm$document11, _vm$document12, _vm$document13, _vm$document13$action, _vm$$v3, _vm$$v3$formData, _vm$$v4, _vm$$v4$formData, _vm$$v5, _vm$$v5$formData, _vm$dictionaries$acti, _vm$document14, _vm$document14$action, _vm$document15, _vm$document15$action, _vm$document16, _vm$document16$action, _vm$document17;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "accounting-document-module"
  }, [_c('sygni-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showModal,
      expression: "showModal"
    }],
    attrs: {
      "isLoading": _vm.isLoading,
      "cancelText": "",
      "confirmText": "OK",
      "description": "You should save these changes first",
      "header": "There are unsaved changes"
    },
    on: {
      "cancel": _vm.closeModal,
      "close": _vm.closeModal,
      "confirm": _vm.closeModal
    }
  }), _c('sygni-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showExitConfirmationModal,
      expression: "showExitConfirmationModal"
    }],
    attrs: {
      "isLoading": _vm.isLoading,
      "cancelText": "No, go back",
      "confirmText": "Yes",
      "description": "Do you want to close this document without saving these changes?",
      "header": "There are unsaved changes"
    },
    on: {
      "cancel": _vm.closeExitConfirmationModal,
      "close": _vm.closeExitConfirmationModal,
      "confirm": _vm.exitConfirmationModalConfirmed
    }
  }), _c('sygni-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showRejectionModal,
      expression: "showRejectionModal"
    }],
    attrs: {
      "isLoading": _vm.isRejectionModalLoading,
      "cancelText": "Cancel",
      "confirmText": "Reject document",
      "header": "Why are you rejecting this document?"
    },
    on: {
      "cancel": _vm.closeRejectionModal,
      "close": _vm.closeRejectionModal,
      "confirm": _vm.rejectDocument
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('sygni-text-area', {
          staticClass: "secondary",
          attrs: {
            "label": "REASON"
          },
          model: {
            value: _vm.formData.note.value,
            callback: function callback($$v) {
              _vm.$set(_vm.formData.note, "value", $$v);
            },
            expression: "formData.note.value"
          }
        })];
      },
      proxy: true
    }])
  }), _c('sygni-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showReportModal,
      expression: "showReportModal"
    }],
    attrs: {
      "isLoading": _vm.isReportModalLoading,
      "disabled": _vm.isReportActionDisabled,
      "cancelText": "Cancel",
      "confirmText": "Send",
      "header": "Report problem",
      "description": "Tell us about problem with this document."
    },
    on: {
      "cancel": _vm.closeReportModal,
      "close": _vm.closeReportModal,
      "confirm": _vm.reportDocument
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('sygni-text-area', {
          staticClass: "secondary",
          attrs: {
            "label": "DESCRIPTION"
          },
          model: {
            value: _vm.reportComment,
            callback: function callback($$v) {
              _vm.reportComment = $$v;
            },
            expression: "reportComment"
          }
        })];
      },
      proxy: true
    }])
  }), _c('sygni-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showChangeGlModal,
      expression: "showChangeGlModal"
    }],
    attrs: {
      "isLoading": _vm.isChangeGlModalLoading,
      "disabled": _vm.isChangeGlActionDisabled,
      "cancelText": "Cancel",
      "confirmText": "Send",
      "header": "Request GL change",
      "description": "Tell us why you're requesting this change."
    },
    on: {
      "cancel": _vm.closeChangeGlModal,
      "close": _vm.closeChangeGlModal,
      "confirm": _vm.requestGlChange
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('sygni-text-area', {
          staticClass: "secondary",
          attrs: {
            "label": "DESCRIPTION"
          },
          model: {
            value: _vm.changeGlComment,
            callback: function callback($$v) {
              _vm.changeGlComment = $$v;
            },
            expression: "changeGlComment"
          }
        })];
      },
      proxy: true
    }])
  }), _c('sygni-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showCancelModal,
      expression: "showCancelModal"
    }],
    attrs: {
      "isLoading": _vm.isCancelModalLoading,
      "cancelText": "No, go back",
      "confirmText": "Yes",
      "description": "Do you really want to cancel this document?",
      "header": "Cancel document"
    },
    on: {
      "cancel": _vm.closeCancelModal,
      "close": _vm.closeCancelModal,
      "confirm": _vm.cancelDocument
    }
  }), _vm.showBankAccountModal ? _c('sygni-modal', {
    attrs: {
      "isLoading": _vm.isPaymentPackageLoading,
      "header": "Payment Package Details",
      "cancelText": "Cancel",
      "confirmText": "Generate package",
      "size": "medium"
    },
    on: {
      "cancel": _vm.closeBankAccountModal,
      "confirm": _vm.generatePaymentPackage,
      "close": _vm.closeBankAccountModal
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        var _vm$$v, _vm$$v2;

        return [_c('div', {
          staticClass: "sygni-modal-form"
        }, [_c('p', {
          staticClass: "sygni-modal-form__header"
        }, [_vm._v("1. Make payment from the bank account:")]), _c('div', {
          staticClass: "sygni-modal-form__group"
        }, [_c('sygni-select', {
          staticClass: "secondary",
          attrs: {
            "options": _vm.formattedBankAccounts,
            "clear-on-select": false,
            "validation": (_vm$$v = _vm.$v) === null || _vm$$v === void 0 ? void 0 : _vm$$v.selectedBankAccount,
            "label": "Bank account"
          },
          model: {
            value: _vm.selectedBankAccount,
            callback: function callback($$v) {
              _vm.selectedBankAccount = $$v;
            },
            expression: "selectedBankAccount"
          }
        }), _c('div', {
          staticClass: "sygni-modal-form__row"
        }, [_c('div', {
          staticClass: "sygni-modal-form__row-data"
        }, [_c('label', {
          staticClass: "debtor-data mb-3"
        }, [_c('span', {
          staticClass: "debtor-data__title"
        }, [_vm._v(" NAME: ")]), _vm._v(" " + _vm._s(_vm.selectedBankName || '') + " ")]), _c('label', {
          staticClass: "debtor-data mb-3"
        }, [_c('span', {
          staticClass: "debtor-data__title"
        }, [_vm._v(" CURRENCY: ")]), _vm._v(" " + _vm._s(_vm.selectedBankCurrency || '') + " ")])]), _c('div', {
          staticClass: "sygni-modal-form__row-checkbox"
        }, [_c('sygni-checkbox', {
          staticClass: "secondary",
          attrs: {
            "clickableLabel": true
          },
          model: {
            value: _vm.isSepa,
            callback: function callback($$v) {
              _vm.isSepa = $$v;
            },
            expression: "isSepa"
          }
        }, [_vm._v("SEPA")])], 1)])], 1)]), _c('div', {
          staticClass: "sygni-modal-form"
        }, [_c('p', {
          staticClass: "sygni-modal-form__header"
        }, [_vm._v("2. Execute payment package:")]), _c('div', {
          staticClass: "sygni-modal-form__group"
        }, [_c('div', {
          staticClass: "sygni-modal-form__radios"
        }, [_c('sygni-radio', {
          attrs: {
            "group": 'customPaymentDate',
            "radioValue": true,
            "label": "on a custom date"
          },
          model: {
            value: _vm.useCustomPaymentDate,
            callback: function callback($$v) {
              _vm.useCustomPaymentDate = $$v;
            },
            expression: "useCustomPaymentDate"
          }
        }), _c('sygni-radio', {
          attrs: {
            "group": 'customPaymentDate',
            "radioValue": false,
            "label": "at date of payment of each document"
          },
          model: {
            value: _vm.useCustomPaymentDate,
            callback: function callback($$v) {
              _vm.useCustomPaymentDate = $$v;
            },
            expression: "useCustomPaymentDate"
          }
        })], 1), _c('sygni-date-picker', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.useCustomPaymentDate,
            expression: "useCustomPaymentDate"
          }],
          attrs: {
            "format": "YYYY-MM-DD",
            "validation": (_vm$$v2 = _vm.$v) === null || _vm$$v2 === void 0 ? void 0 : _vm$$v2.customPaymentDate
          },
          model: {
            value: _vm.customPaymentDate,
            callback: function callback($$v) {
              _vm.customPaymentDate = $$v;
            },
            expression: "customPaymentDate"
          }
        })], 1)]), _c('div', {
          staticClass: "sygni-modal-form"
        }, [_c('p', {
          staticClass: "sygni-modal-form__header"
        }, [_vm._v("3. Payment package name:")]), _c('div', {
          staticClass: "sygni-modal-form__group"
        }, [_c('div', {
          staticClass: "sygni-modal-form__inputs"
        }, [_c('sygni-input', {
          attrs: {
            "label": "",
            "placeholder": _vm.defaultPaymentPackageName
          },
          model: {
            value: _vm.customPackageName,
            callback: function callback($$v) {
              _vm.customPackageName = $$v;
            },
            expression: "customPackageName"
          }
        })], 1)])])];
      },
      proxy: true
    }], null, false, 3167997436)
  }) : _vm._e(), _c('sygni-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showBookModal,
      expression: "showBookModal"
    }],
    attrs: {
      "isLoading": _vm.isBookModalLoading,
      "cancelText": "No",
      "confirmText": "Yes",
      "header": "Has a cash/bank report been created?",
      "description": "In order to book this document you must be sure that cash/bank report is created."
    },
    on: {
      "cancel": _vm.closeBookModal,
      "close": _vm.closeBookModal,
      "confirm": _vm.bookDocument
    }
  }), _c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "accounting-document-module__container"
  }, [_c('div', {
    staticClass: "col-12 accounting-document-module__bar"
  }, [_c('div', {
    staticClass: "document-bar"
  }, [_c('div', {
    staticClass: "document-bar__column"
  }, [_c('div', {
    staticClass: "document-bar__statuses"
  }, [_vm.document ? _c('div', {
    staticClass: "status"
  }, [_c('p', {
    staticClass: "status__label"
  }, [_vm._v("DOCUMENT STATUS")]), _c('div', {
    class: ['status__tag', _vm.getDocumentStatusClassName((_vm$document = _vm.document) === null || _vm$document === void 0 ? void 0 : _vm$document.status)]
  }, [_c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: _vm.tooltipStatusMessage,
      expression: "tooltipStatusMessage",
      modifiers: {
        "hover": true
      }
    }]
  }, [_vm._v(_vm._s(_vm.statusText((_vm$document2 = _vm.document) === null || _vm$document2 === void 0 ? void 0 : _vm$document2.status)))])])]) : _vm._e(), _vm.document ? _c('div', {
    staticClass: "status"
  }, [_c('p', {
    staticClass: "status__label"
  }, [_vm._v("PAYMENT STATUS")]), _vm.document.paymentStatus == 'paid' ? _c('div', {
    staticClass: "status__tag inline text-success"
  }, [_vm._v("Paid")]) : _vm._e(), _vm.document.paymentStatus == 'scheduled' ? _c('div', {
    staticClass: "status__tag inline text-primary"
  }, [_vm._v("Scheduled ")]) : _vm._e(), _vm.document.paymentStatus == 'unpaid' && _vm.document.paymentDate && new Date(_vm.document.paymentDate) < new Date() ? _c('div', {
    staticClass: "status__tag inline text-danger"
  }, [_vm._v(" Overdue ")]) : _vm._e(), _vm.document.paymentStatus == 'unpaid' && (!_vm.document.paymentDate || new Date(_vm.document.paymentDate) >= new Date()) ? _c('div', {
    staticClass: "status__tag inline text-danger"
  }, [_vm._v(" Unpaid ")]) : _vm._e()]) : _vm._e(), _vm.document ? _c('div', {
    staticClass: "status"
  }, [_c('p', {
    staticClass: "status__label"
  }, [_vm._v("ID:")]), _c('div', {
    staticClass: "status__tag inline"
  }, [_vm._v(_vm._s((_vm$document3 = _vm.document) !== null && _vm$document3 !== void 0 && _vm$document3.accountingId ? _vm.document.accountingId : '-'))])]) : _vm._e(), _vm.document ? _c('div', {
    staticClass: "status"
  }, [_c('p', {
    staticClass: "status__label"
  }, [_vm._v("BUYER TIN:")]), _c('p', {
    staticClass: "status__tag inline"
  }, [_vm._v(_vm._s((_vm$document4 = _vm.document) !== null && _vm$document4 !== void 0 && _vm$document4.ownerTaxNumber ? _vm.document.ownerTaxNumber : '-'))])]) : _vm._e()])]), (_vm$document5 = _vm.document) !== null && _vm$document5 !== void 0 && _vm$document5.actions.includes('book') || (_vm$document6 = _vm.document) !== null && _vm$document6 !== void 0 && _vm$document6.actions.includes('synchronize-counterparty') || (_vm$document7 = _vm.document) !== null && _vm$document7 !== void 0 && _vm$document7.actions.includes('cancel') ? _c('div', {
    staticClass: "document-bar__column"
  }, [_c('div', {
    staticClass: "document-bar__actions"
  }, [(_vm$document8 = _vm.document) !== null && _vm$document8 !== void 0 && _vm$document8.actions.includes('book') ? _c('sygni-rect-button', {
    staticClass: "outline success",
    on: {
      "click": _vm.handleBookDocumentAction
    }
  }, [_vm._v("Book")]) : _vm._e(), (_vm$document9 = _vm.document) !== null && _vm$document9 !== void 0 && _vm$document9.actions.includes('synchronize-counterparty') ? _c('sygni-rect-button', {
    staticClass: "outline primary",
    on: {
      "click": _vm.synchronizeCounterparty
    }
  }, [_vm._v("Synchronise")]) : _vm._e(), (_vm$document10 = _vm.document) !== null && _vm$document10 !== void 0 && _vm$document10.actions.includes('cancel') ? _c('sygni-rect-button', {
    staticClass: "outline danger",
    on: {
      "click": _vm.openCancelModal
    }
  }, [_vm._v("Cancel")]) : _vm._e()], 1)]) : _vm._e()])]), _c('div', {
    staticClass: "col-12 accounting-document-module__preview"
  }, [_c('div', {
    staticClass: "document-box"
  }, [_c('div', {
    class: ['document-box__loader', _vm.isLoading ? 'active' : '']
  }, [_c('div', {
    staticClass: "spinner"
  }, [_c('b-spinner')], 1)]), _c('div', {
    staticClass: "document-box__inner"
  }, [_c('div', {
    ref: "scrollableColumn",
    staticClass: "document-box__column"
  }, [_c('div', {
    staticClass: "document-box__form"
  }, [(_vm$document11 = _vm.document) !== null && _vm$document11 !== void 0 && _vm$document11.note ? _c('div', {
    staticClass: "document-box__note"
  }, [_vm._m(0), _c('div', {
    staticClass: "document-box__note-text"
  }, [_vm._v(" " + _vm._s((_vm$document12 = _vm.document) !== null && _vm$document12 !== void 0 && _vm$document12.note ? _vm.document.note : '') + " ")])]) : _vm._e(), _c('sygni-container-title', {
    attrs: {
      "inline": true
    }
  }, [_vm._v("Counterparty Details")]), _c('div', {
    staticClass: "document-box__form-row"
  }, [_c('sygni-select', {
    staticClass: "show-placeholder",
    attrs: {
      "placeholder": _vm.formData.counterPartyName.value,
      "add-option-btn": true,
      "clearOnSelect": true,
      "disabled": _vm.cantEdit || _vm.isUltimatelyFailed,
      "internal-search": false,
      "allow-empty": false,
      "label": 'COUNTERPARTY NAME',
      "limit": 10,
      "options": _vm.filteredCounterPartyOptions,
      "searchable": true,
      "emptyText": "Type for search",
      "trackBy": "value"
    },
    on: {
      "addOption": _vm.addCounterPartyOption,
      "input": _vm.loadSelectedCounterParty,
      "searchChange": _vm.filterCounterPartyOptions
    },
    model: {
      value: _vm.formData.counterPartyName.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.counterPartyName, "value", $$v);
      },
      expression: "formData.counterPartyName.value"
    }
  })], 1), _c('div', {
    staticClass: "document-box__form-row"
  }, [_c('sygni-input', {
    attrs: {
      "disabled": _vm.cantEdit || _vm.isUltimatelyFailed,
      "label": 'COUNTERPARTY TIN'
    },
    on: {
      "input": _vm.handleFieldChange,
      "blur": function blur($event) {
        return _vm.checkWhitelist(true);
      }
    },
    model: {
      value: _vm.formData.counterPartyTin.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.counterPartyTin, "value", $$v);
      },
      expression: "formData.counterPartyTin.value"
    }
  }), _c('div', {
    staticClass: "sygni-input"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("TAXPAYER STATUS")]), _c('div', {
    staticClass: "taxpayer-container"
  }, [_c('sygni-rounded-button', {
    class: ['filled round', _vm.getCounterPartyTaxPayerStatusClass(_vm.formData.counterPartyTaxpayerStatus.value)]
  }, [_vm._v(" " + _vm._s(_vm.getCounterPartyTaxPayerStatusText(_vm.formData.counterPartyTaxpayerStatus.value)) + " ")])], 1)])], 1), _c('div', {
    staticClass: "document-box__form-row"
  }, [_c('sygni-input', {
    attrs: {
      "disabled": _vm.cantEdit || _vm.isUltimatelyFailed,
      "label": 'STREET'
    },
    on: {
      "input": _vm.handleFieldChange
    },
    model: {
      value: _vm.formData.street.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.street, "value", $$v);
      },
      expression: "formData.street.value"
    }
  }), _c('div', {
    staticClass: "sygni-input sygni-input--group"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("STREET NUMBER / LOCAL")]), _c('div', {
    staticClass: "sygni-input-container-half"
  }, [_c('sygni-input', {
    staticClass: "hide-label",
    attrs: {
      "disabled": _vm.cantEdit || _vm.isUltimatelyFailed,
      "label": 'STREET NUMBER'
    },
    on: {
      "input": _vm.handleFieldChange
    },
    model: {
      value: _vm.formData.streetNumber.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.streetNumber, "value", $$v);
      },
      expression: "formData.streetNumber.value"
    }
  }), _c('sygni-input', {
    staticClass: "hide-label",
    attrs: {
      "disabled": _vm.cantEdit || _vm.isUltimatelyFailed,
      "label": 'LOCAL'
    },
    on: {
      "input": _vm.handleFieldChange
    },
    model: {
      value: _vm.formData.apartment.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.apartment, "value", $$v);
      },
      expression: "formData.apartment.value"
    }
  })], 1)])], 1), _c('div', {
    staticClass: "document-box__form-row"
  }, [_c('sygni-input', {
    attrs: {
      "disabled": _vm.cantEdit || _vm.isUltimatelyFailed,
      "label": 'CITY'
    },
    on: {
      "input": _vm.handleFieldChange
    },
    model: {
      value: _vm.formData.city.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.city, "value", $$v);
      },
      expression: "formData.city.value"
    }
  }), _c('sygni-input', {
    attrs: {
      "disabled": _vm.cantEdit || _vm.isUltimatelyFailed,
      "label": 'ZIP CODE'
    },
    on: {
      "input": _vm.handleFieldChange
    },
    model: {
      value: _vm.formData.zipCode.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.zipCode, "value", $$v);
      },
      expression: "formData.zipCode.value"
    }
  })], 1), _c('div', {
    staticClass: "document-box__form-row"
  }, [_c('sygni-checkbox', {
    staticClass: "secondary",
    attrs: {
      "disabled": _vm.cantEdit || _vm.isUltimatelyFailed,
      "label": "CASH METHOD"
    },
    on: {
      "changedValue": _vm.handleFieldChange
    },
    model: {
      value: _vm.formData.cashMethod.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.cashMethod, "value", $$v);
      },
      expression: "formData.cashMethod.value"
    }
  }), _c('sygni-select', {
    attrs: {
      "disabled": _vm.cantEdit || _vm.isUltimatelyFailed,
      "label": 'COUNTRY',
      "options": _vm.dictionaries.country,
      "searchable": true
    },
    on: {
      "input": _vm.handleFieldChange
    },
    model: {
      value: _vm.formData.counterPartyCountry.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.counterPartyCountry, "value", $$v);
      },
      expression: "formData.counterPartyCountry.value"
    }
  })], 1)], 1), _c('div', {
    staticClass: "document-box__form"
  }, [_c('sygni-container-title', {
    attrs: {
      "inline": true
    }
  }, [_vm._v("Headline")]), _c('div', {
    staticClass: "document-box__form-row"
  }, [_c('sygni-select', {
    attrs: {
      "disabled": _vm.cantEdit || _vm.isUltimatelyFailed,
      "label": 'DOCUMENT TYPE',
      "options": _vm.dictionaries.type,
      "searchable": true
    },
    on: {
      "input": _vm.handleDocumentTypeChange
    },
    model: {
      value: _vm.formData.documentType.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.documentType, "value", $$v);
      },
      expression: "formData.documentType.value"
    }
  }), _c('sygni-select', {
    attrs: {
      "disabled": true,
      "label": 'REGISTER TYPE',
      "options": _vm.dictionaries.registerTypes
    },
    on: {
      "input": _vm.handleFieldChange
    },
    model: {
      value: _vm.formData.registerType.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.registerType, "value", $$v);
      },
      expression: "formData.registerType.value"
    }
  })], 1), _c('div', {
    staticClass: "document-box__form-row"
  }, [_c('sygni-input', {
    attrs: {
      "disabled": _vm.cantEdit || _vm.isUltimatelyFailed,
      "label": 'DOCUMENT NUMBER'
    },
    on: {
      "input": _vm.handleFieldChange
    },
    model: {
      value: _vm.formData.documentNumber.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.documentNumber, "value", $$v);
      },
      expression: "formData.documentNumber.value"
    }
  }), _c('sygni-input', {
    attrs: {
      "disabled": _vm.cantEdit || _vm.isUltimatelyFailed || !_vm.isCorrectiveInvoice,
      "label": 'CORRECTED DOC NO.'
    },
    on: {
      "input": _vm.handleFieldChange
    },
    model: {
      value: _vm.formData.correctedDocumentNumber.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.correctedDocumentNumber, "value", $$v);
      },
      expression: "formData.correctedDocumentNumber.value"
    }
  })], 1), _c('div', {
    staticClass: "document-box__form-row"
  }, [_c('sygni-date-picker', {
    staticClass: "border-collapse",
    attrs: {
      "disabled": _vm.cantEdit || _vm.isUltimatelyFailed,
      "label": 'ISSUE DATE'
    },
    on: {
      "input": _vm.handleFieldChange
    },
    model: {
      value: _vm.formData.issueDate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.issueDate, "value", $$v);
      },
      expression: "formData.issueDate.value"
    }
  }), _c('sygni-date-picker', {
    staticClass: "border-collapse",
    attrs: {
      "disabled": _vm.cantEdit || _vm.isUltimatelyFailed,
      "label": 'PAYMENT DATE'
    },
    on: {
      "input": _vm.handleFieldChange
    },
    model: {
      value: _vm.formData.paymentDate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.paymentDate, "value", $$v);
      },
      expression: "formData.paymentDate.value"
    }
  })], 1), _c('div', {
    staticClass: "document-box__form-row"
  }, [_c('sygni-date-picker', {
    staticClass: "border-collapse",
    attrs: {
      "disabled": _vm.cantEdit || _vm.isUltimatelyFailed,
      "label": 'SALE DATE'
    },
    on: {
      "input": _vm.handleFieldChange
    },
    model: {
      value: _vm.formData.saleDate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.saleDate, "value", $$v);
      },
      expression: "formData.saleDate.value"
    }
  }), _c('sygni-date-picker', {
    staticClass: "border-collapse",
    attrs: {
      "disabled": true,
      "label": 'ADDITION DATE'
    },
    on: {
      "input": _vm.handleFieldChange
    },
    model: {
      value: _vm.formData.additionDate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.additionDate, "value", $$v);
      },
      expression: "formData.additionDate.value"
    }
  })], 1), _c('div', {
    staticClass: "document-box__form-row"
  }, [_c('sygni-date-picker', {
    staticClass: "border-collapse",
    attrs: {
      "disabled": (_vm$document13 = _vm.document) !== null && _vm$document13 !== void 0 && (_vm$document13$action = _vm$document13.actions) !== null && _vm$document13$action !== void 0 && _vm$document13$action.includes('edit-accounting-date') ? false : true,
      "label": 'ACCOUNTING DATE'
    },
    on: {
      "input": _vm.handleFieldChange
    },
    model: {
      value: _vm.formData.documentReceiptDate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.documentReceiptDate, "value", $$v);
      },
      expression: "formData.documentReceiptDate.value"
    }
  }), _c('sygni-select', {
    attrs: {
      "disabled": _vm.cantEdit || _vm.isUltimatelyFailed,
      "label": 'PAYMENT METHOD',
      "options": _vm.dictionaries.paymentMethod,
      "searchable": true
    },
    on: {
      "input": _vm.handleFieldChange
    },
    model: {
      value: _vm.formData.paymentMethod.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.paymentMethod, "value", $$v);
      },
      expression: "formData.paymentMethod.value"
    }
  })], 1), _c('div', {
    staticClass: "document-box__form-row"
  }, [_c('sygni-select', {
    staticClass: "payment-status-select",
    attrs: {
      "disabled": _vm.cantEditPaymentStatus || _vm.isUltimatelyFailed,
      "label": 'PAYMENT STATUS',
      "options": _vm.dictionaries.paymentStatuses,
      "searchable": true
    },
    on: {
      "input": _vm.handleFieldChange
    },
    scopedSlots: _vm._u([{
      key: "labelAddon",
      fn: function fn() {
        var _vm$formData$paymentM;

        return [((_vm$formData$paymentM = _vm.formData.paymentMethod) === null || _vm$formData$paymentM === void 0 ? void 0 : _vm$formData$paymentM.value) === 'Bank Transfer' ? _c('sygni-checkbox', {
          staticClass: "no-wrap secondary",
          attrs: {
            "label": "PREPAID"
          },
          model: {
            value: _vm.formData.prepaid.value,
            callback: function callback($$v) {
              _vm.$set(_vm.formData.prepaid, "value", $$v);
            },
            expression: "formData.prepaid.value"
          }
        }) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.formData.paymentStatus.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.paymentStatus, "value", $$v);
      },
      expression: "formData.paymentStatus.value"
    }
  }), _c('sygni-select', {
    attrs: {
      "disabled": _vm.cantEdit || _vm.isUltimatelyFailed,
      "searchable": "",
      "label": 'CURRENCY',
      "options": _vm.globalCurrencies
    },
    on: {
      "input": _vm.handleFieldChange
    },
    model: {
      value: _vm.formData.currency.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.currency, "value", $$v);
      },
      expression: "formData.currency.value"
    }
  })], 1), _c('div', {
    staticClass: "document-box__form-row"
  }, [_c('div', {
    staticClass: "document-box__bank-account"
  }, [_c('sygni-input', {
    key: _vm.bankAccountDisplayFormat,
    ref: "bankAccountSygniInput",
    attrs: {
      "disabled": _vm.disableBankAccountNumber,
      "displayCheck": _vm.bankAccountDisplayCheck,
      "label": 'BANK ACCOUNT',
      "mask": _vm.bankAccountDisplayFormat,
      "validation": (_vm$$v3 = _vm.$v) === null || _vm$$v3 === void 0 ? void 0 : (_vm$$v3$formData = _vm$$v3.formData) === null || _vm$$v3$formData === void 0 ? void 0 : _vm$$v3$formData.bankAccount
    },
    on: {
      "blur": _vm.overwriteBankAccount,
      "input": _vm.handleBankAccountKeyEvent
    },
    scopedSlots: _vm._u([{
      key: "displayCheck",
      fn: function fn() {
        var _vm$whitelistInfo, _vm$whitelistInfo2, _vm$whitelistInfo3, _vm$whitelistInfo4;

        return [(_vm$whitelistInfo = _vm.whitelistInfo) !== null && _vm$whitelistInfo !== void 0 && _vm$whitelistInfo.message ? [(_vm$whitelistInfo2 = _vm.whitelistInfo) !== null && _vm$whitelistInfo2 !== void 0 && _vm$whitelistInfo2.checked ? _c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip:hover",
            value: (_vm$whitelistInfo3 = _vm.whitelistInfo) === null || _vm$whitelistInfo3 === void 0 ? void 0 : _vm$whitelistInfo3.message,
            expression: "whitelistInfo?.message",
            arg: "hover"
          }],
          staticClass: "confirmation-box"
        }, [_c('div', {
          staticClass: "success-box fe_success_box"
        }, [_c('svg', {
          attrs: {
            "id": "Component_24_260",
            "data-name": "Component 24 – 260",
            "xmlns": "http://www.w3.org/2000/svg",
            "width": "16",
            "height": "16",
            "viewBox": "0 0 16 16"
          }
        }, [_c('path', {
          attrs: {
            "id": "Path_1170",
            "data-name": "Path 1170",
            "d": "M4.084,8.372h0l3.3,3.3,5.264-5.264",
            "transform": "translate(-0.367 -1.044)",
            "fill": "none",
            "stroke": "#fff",
            "stroke-width": "1.5"
          }
        })])])]) : _c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: (_vm$whitelistInfo4 = _vm.whitelistInfo) === null || _vm$whitelistInfo4 === void 0 ? void 0 : _vm$whitelistInfo4.message,
            expression: "whitelistInfo?.message",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "confirmation-box fe_error_box"
        }, [_c('div', {
          staticClass: "error-box"
        }, [_c('span'), _c('span')])])] : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.formData.bankAccount.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.bankAccount, "value", $$v);
      },
      expression: "formData.bankAccount.value"
    }
  }), _c('sygni-select', {
    attrs: {
      "disabled": _vm.disableBankAccountNumber,
      "label": 'COUNTRY',
      "validation": (_vm$$v4 = _vm.$v) === null || _vm$$v4 === void 0 ? void 0 : (_vm$$v4$formData = _vm$$v4.formData) === null || _vm$$v4$formData === void 0 ? void 0 : _vm$$v4$formData.bankAccountCountry,
      "options": _vm.dictionaries.country,
      "searchable": true,
      "allowEmpty": false
    },
    on: {
      "input": _vm.handleFieldChange
    },
    model: {
      value: _vm.formData.bankAccountCountry.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.bankAccountCountry, "value", $$v);
      },
      expression: "formData.bankAccountCountry.value"
    }
  })], 1)]), _c('div', {
    staticClass: "document-box__form-row"
  }, [_c('sygni-input', {
    staticClass: "uppercase",
    attrs: {
      "disabled": _vm.disableSwiftCodeNumber,
      "displayCheck": _vm.bankAccountSwiftDisplayCheck,
      "label": 'SWIFT CODE',
      "validation": (_vm$$v5 = _vm.$v) === null || _vm$$v5 === void 0 ? void 0 : (_vm$$v5$formData = _vm$$v5.formData) === null || _vm$$v5$formData === void 0 ? void 0 : _vm$$v5$formData.bankAccountSwift
    },
    on: {
      "blur": _vm.overwriteBankAccountSwift,
      "input": _vm.handleBankAccountSwiftEvent
    },
    model: {
      value: _vm.formData.bankAccountSwift.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.bankAccountSwift, "value", $$v);
      },
      expression: "formData.bankAccountSwift.value"
    }
  })], 1)], 1), _c('div', {
    staticClass: "document-box__form"
  }, [_c('sygni-container-title', {
    attrs: {
      "inline": true
    }
  }, [_vm._v("Accounting")]), _c('div', {
    staticClass: "document-box__form-row"
  }, [_c('sygni-select', {
    attrs: {
      "value": _vm.formData.glAccount.value,
      "disabled": _vm.cantEdit || _vm.isUltimatelyFailed,
      "label": 'GL ACCOUNT',
      "options": _vm.activeGlAccounts,
      "searchable": true
    },
    on: {
      "input": _vm.handleGlAccountChange
    }
  })], 1), ((_vm$dictionaries$acti = _vm.dictionaries.activeCostCenters) === null || _vm$dictionaries$acti === void 0 ? void 0 : _vm$dictionaries$acti.length) > 0 ? _c('div', {
    staticClass: "document-box__form-row"
  }, [!_vm.isLoading ? _c('sygni-select', {
    attrs: {
      "disabled": _vm.cantEdit || _vm.isUltimatelyFailed,
      "label": 'COST CENTER',
      "options": _vm.activeCostCenters,
      "searchable": true
    },
    on: {
      "input": _vm.handleFieldChange
    },
    model: {
      value: _vm.formData.costCenter.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.costCenter, "value", $$v);
      },
      expression: "formData.costCenter.value"
    }
  }) : _vm._e()], 1) : _vm._e()], 1), _c('div', {
    staticClass: "details"
  }, [_c('sygni-container-title', {
    attrs: {
      "inline": true
    }
  }, [_vm._v("Details")]), _c('div', {
    staticClass: "details__groups"
  }, [_vm._l(_vm.formData.details, function (detail, index) {
    var _vm$dictionaries$acti2;

    return _c('div', {
      key: index,
      staticClass: "details__group"
    }, [_c('div', {
      staticClass: "details__row"
    }, [_c('sygni-input', {
      attrs: {
        "disabled": _vm.cantEdit || _vm.isUltimatelyFailed,
        "label": 'DESCRIPTION'
      },
      on: {
        "input": _vm.handleFieldChange
      },
      model: {
        value: detail.description.value,
        callback: function callback($$v) {
          _vm.$set(detail.description, "value", $$v);
        },
        expression: "detail.description.value"
      }
    })], 1), _c('div', {
      staticClass: "details__row"
    }, [_c('sygni-select', {
      attrs: {
        "disabled": _vm.cantEdit || _vm.isUltimatelyFailed,
        "label": 'VAT%',
        "options": _vm.vatRateOptions,
        "searchable": true
      },
      on: {
        "input": function input($event) {
          return _vm.calculateTotals(detail);
        }
      },
      model: {
        value: detail.vatPercent.value,
        callback: function callback($$v) {
          _vm.$set(detail.vatPercent, "value", $$v);
        },
        expression: "detail.vatPercent.value"
      }
    }), _c('sygni-input', {
      attrs: {
        "disabled": _vm.cantEdit || _vm.isUltimatelyFailed,
        "label": 'NET'
      },
      on: {
        "input": function input($event) {
          return _vm.calculateTotals(detail);
        }
      },
      model: {
        value: detail.net.value,
        callback: function callback($$v) {
          _vm.$set(detail.net, "value", $$v);
        },
        expression: "detail.net.value"
      }
    }), _c('sygni-input', {
      attrs: {
        "disabled": _vm.cantEdit || _vm.isUltimatelyFailed,
        "label": 'VAT'
      },
      on: {
        "input": function input($event) {
          return _vm.calculateGross(detail);
        }
      },
      model: {
        value: detail.vat.value,
        callback: function callback($$v) {
          _vm.$set(detail.vat, "value", $$v);
        },
        expression: "detail.vat.value"
      }
    }), _c('sygni-input', {
      attrs: {
        "disabled": _vm.cantEdit || _vm.isUltimatelyFailed,
        "label": 'GROSS'
      },
      on: {
        "input": _vm.handleFieldChange
      },
      model: {
        value: detail.gross.value,
        callback: function callback($$v) {
          _vm.$set(detail.gross, "value", $$v);
        },
        expression: "detail.gross.value"
      }
    }), !_vm.cantEdit && !_vm.isUltimatelyFailed ? _c('div', {
      staticClass: "details__action",
      on: {
        "click": function click($event) {
          return _vm.removeDetail(index);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": _vm._f("getIcon")('DELETE')
      }
    })]) : _vm._e()], 1), _c('div', {
      staticClass: "details__row"
    }, [_c('sygni-toggle-switch', {
      staticClass: "secondary advanced-options-switch",
      attrs: {
        "disabled": _vm.cantEdit || _vm.isUltimatelyFailed,
        "label": 'ADD GL ACCOUNT AND COST CENTER'
      },
      model: {
        value: detail.moreDetails,
        callback: function callback($$v) {
          _vm.$set(detail, "moreDetails", $$v);
        },
        expression: "detail.moreDetails"
      }
    })], 1), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: detail.moreDetails,
        expression: "detail.moreDetails"
      }],
      staticClass: "details__row"
    }, [_c('sygni-select', {
      attrs: {
        "disabled": _vm.cantEdit || _vm.isUltimatelyFailed,
        "label": 'GL ACCOUNT',
        "options": _vm.activeGlAccounts,
        "searchable": true
      },
      on: {
        "input": _vm.handleFieldChange
      },
      model: {
        value: detail.glAccount.value,
        callback: function callback($$v) {
          _vm.$set(detail.glAccount, "value", $$v);
        },
        expression: "detail.glAccount.value"
      }
    }), ((_vm$dictionaries$acti2 = _vm.dictionaries.activeCostCenters) === null || _vm$dictionaries$acti2 === void 0 ? void 0 : _vm$dictionaries$acti2.length) > 0 ? _c('sygni-select', {
      attrs: {
        "disabled": _vm.cantEdit || _vm.isUltimatelyFailed,
        "label": 'COST CENTER',
        "options": _vm.dictionaries.activeCostCenters,
        "searchable": true
      },
      on: {
        "input": _vm.handleFieldChange
      },
      model: {
        value: detail.costCenter.value,
        callback: function callback($$v) {
          _vm.$set(detail.costCenter, "value", $$v);
        },
        expression: "detail.costCenter.value"
      }
    }) : _vm._e()], 1)]);
  }), !_vm.cantEdit && !_vm.isUltimatelyFailed ? _c('a', {
    staticClass: "details__btn gn-primary",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.addDetail.apply(null, arguments);
      }
    }
  }, [_vm._v("Add new detail +")]) : _vm._e()], 2), _c('div', {
    staticClass: "details__totals"
  }, [_c('div', {
    staticClass: "details__totals-group"
  }, [_c('sygni-container-title', {
    attrs: {
      "inline": true
    }
  }, [_vm._v("Total")]), _c('div', {
    staticClass: "details__total"
  }, [_c('span', {
    staticClass: "details__total-label"
  }, [_vm._v("NET")]), _c('p', {
    staticClass: "details__total-value"
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(_vm._s(_vm.totalNet) + " " + _vm._s(_vm.formData.currency.value))])])]), _c('div', {
    staticClass: "details__total"
  }, [_c('span', {
    staticClass: "details__total-label"
  }, [_vm._v("VAT")]), _c('p', {
    staticClass: "details__total-value"
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(_vm._s(_vm.totalVat) + " " + _vm._s(_vm.formData.currency.value))])])]), _c('div', {
    staticClass: "details__total"
  }, [_c('span', {
    staticClass: "details__total-label"
  }, [_vm._v("GROSS")]), _c('p', {
    staticClass: "details__total-value text-danger"
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(_vm._s(_vm.totalGross) + " " + _vm._s(_vm.formData.currency.value))])])])], 1), _vm.totalZw ? _c('div', {
    staticClass: "details__totals-group details__totals-group--small"
  }, [_c('sygni-container-title', {
    attrs: {
      "inline": true
    }
  }, [_vm._v("ZW")]), _c('div', {
    staticClass: "details__total"
  }, [_c('span', {
    staticClass: "details__total-label"
  }, [_vm._v("NET")]), _c('p', {
    staticClass: "details__total-value"
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(_vm._s(_vm.totalZw.net) + " " + _vm._s(_vm.formData.currency.value))])])]), _c('div', {
    staticClass: "details__total"
  }, [_c('span', {
    staticClass: "details__total-label"
  }, [_vm._v("VAT")]), _c('p', {
    staticClass: "details__total-value"
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(_vm._s(_vm.totalZw.vat) + " " + _vm._s(_vm.formData.currency.value))])])]), _c('div', {
    staticClass: "details__total"
  }, [_c('span', {
    staticClass: "details__total-label"
  }, [_vm._v("GROSS")]), _c('p', {
    staticClass: "details__total-value text-danger"
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(_vm._s(_vm.totalZw.gross) + " " + _vm._s(_vm.formData.currency.value))])])])], 1) : _vm._e(), _vm.totalNp ? _c('div', {
    staticClass: "details__totals-group details__totals-group--small"
  }, [_c('sygni-container-title', {
    attrs: {
      "inline": true
    }
  }, [_vm._v("NP")]), _c('div', {
    staticClass: "details__total"
  }, [_c('span', {
    staticClass: "details__total-label"
  }, [_vm._v("NET")]), _c('p', {
    staticClass: "details__total-value"
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(_vm._s(_vm.totalNp.net) + " " + _vm._s(_vm.formData.currency.value))])])]), _c('div', {
    staticClass: "details__total"
  }, [_c('span', {
    staticClass: "details__total-label"
  }, [_vm._v("VAT")]), _c('p', {
    staticClass: "details__total-value"
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(_vm._s(_vm.totalNp.vat) + " " + _vm._s(_vm.formData.currency.value))])])]), _c('div', {
    staticClass: "details__total"
  }, [_c('span', {
    staticClass: "details__total-label"
  }, [_vm._v("GROSS")]), _c('p', {
    staticClass: "details__total-value text-danger"
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(_vm._s(_vm.totalNp.gross) + " " + _vm._s(_vm.formData.currency.value))])])])], 1) : _vm._e(), _vm.totalZero ? _c('div', {
    staticClass: "details__totals-group details__totals-group--small"
  }, [_c('sygni-container-title', {
    attrs: {
      "inline": true
    }
  }, [_vm._v("0%")]), _c('div', {
    staticClass: "details__total"
  }, [_c('span', {
    staticClass: "details__total-label"
  }, [_vm._v("NET")]), _c('p', {
    staticClass: "details__total-value"
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(_vm._s(_vm.totalZero.net) + " " + _vm._s(_vm.formData.currency.value))])])]), _c('div', {
    staticClass: "details__total"
  }, [_c('span', {
    staticClass: "details__total-label"
  }, [_vm._v("VAT")]), _c('p', {
    staticClass: "details__total-value"
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(_vm._s(_vm.totalZero.vat) + " " + _vm._s(_vm.formData.currency.value))])])]), _c('div', {
    staticClass: "details__total"
  }, [_c('span', {
    staticClass: "details__total-label"
  }, [_vm._v("GROSS")]), _c('p', {
    staticClass: "details__total-value text-danger"
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(_vm._s(_vm.totalZero.gross) + " " + _vm._s(_vm.formData.currency.value))])])])], 1) : _vm._e(), _vm.totalFour ? _c('div', {
    staticClass: "details__totals-group details__totals-group--small"
  }, [_c('sygni-container-title', {
    attrs: {
      "inline": true
    }
  }, [_vm._v("4%")]), _c('div', {
    staticClass: "details__total"
  }, [_c('span', {
    staticClass: "details__total-label"
  }, [_vm._v("NET")]), _c('p', {
    staticClass: "details__total-value"
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(_vm._s(_vm.totalFour.net) + " " + _vm._s(_vm.formData.currency.value))])])]), _c('div', {
    staticClass: "details__total"
  }, [_c('span', {
    staticClass: "details__total-label"
  }, [_vm._v("VAT")]), _c('p', {
    staticClass: "details__total-value"
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(_vm._s(_vm.totalFour.vat) + " " + _vm._s(_vm.formData.currency.value))])])]), _c('div', {
    staticClass: "details__total"
  }, [_c('span', {
    staticClass: "details__total-label"
  }, [_vm._v("GROSS")]), _c('p', {
    staticClass: "details__total-value text-danger"
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(_vm._s(_vm.totalFour.gross) + " " + _vm._s(_vm.formData.currency.value))])])])], 1) : _vm._e(), _vm.totalFive ? _c('div', {
    staticClass: "details__totals-group details__totals-group--small"
  }, [_c('sygni-container-title', {
    attrs: {
      "inline": true
    }
  }, [_vm._v("5%")]), _c('div', {
    staticClass: "details__total"
  }, [_c('span', {
    staticClass: "details__total-label"
  }, [_vm._v("NET")]), _c('p', {
    staticClass: "details__total-value"
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(_vm._s(_vm.totalFive.net) + " " + _vm._s(_vm.formData.currency.value))])])]), _c('div', {
    staticClass: "details__total"
  }, [_c('span', {
    staticClass: "details__total-label"
  }, [_vm._v("VAT")]), _c('p', {
    staticClass: "details__total-value"
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(_vm._s(_vm.totalFive.vat) + " " + _vm._s(_vm.formData.currency.value))])])]), _c('div', {
    staticClass: "details__total"
  }, [_c('span', {
    staticClass: "details__total-label"
  }, [_vm._v("GROSS")]), _c('p', {
    staticClass: "details__total-value text-danger"
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(_vm._s(_vm.totalFive.gross) + " " + _vm._s(_vm.formData.currency.value))])])])], 1) : _vm._e(), _vm.totalEight ? _c('div', {
    staticClass: "details__totals-group details__totals-group--small"
  }, [_c('sygni-container-title', {
    attrs: {
      "inline": true
    }
  }, [_vm._v("8%")]), _c('div', {
    staticClass: "details__total"
  }, [_c('span', {
    staticClass: "details__total-label"
  }, [_vm._v("NET")]), _c('p', {
    staticClass: "details__total-value"
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(_vm._s(_vm.totalEight.net) + " " + _vm._s(_vm.formData.currency.value))])])]), _c('div', {
    staticClass: "details__total"
  }, [_c('span', {
    staticClass: "details__total-label"
  }, [_vm._v("VAT")]), _c('p', {
    staticClass: "details__total-value"
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(_vm._s(_vm.totalEight.vat) + " " + _vm._s(_vm.formData.currency.value))])])]), _c('div', {
    staticClass: "details__total"
  }, [_c('span', {
    staticClass: "details__total-label"
  }, [_vm._v("GROSS")]), _c('p', {
    staticClass: "details__total-value text-danger"
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(_vm._s(_vm.totalEight.gross) + " " + _vm._s(_vm.formData.currency.value))])])])], 1) : _vm._e(), _vm.total23 ? _c('div', {
    staticClass: "details__totals-group details__totals-group--small"
  }, [_c('sygni-container-title', {
    attrs: {
      "inline": true
    }
  }, [_vm._v("23%")]), _c('div', {
    staticClass: "details__total"
  }, [_c('span', {
    staticClass: "details__total-label"
  }, [_vm._v("NET")]), _c('p', {
    staticClass: "details__total-value"
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(_vm._s(_vm.total23.net) + " " + _vm._s(_vm.formData.currency.value))])])]), _c('div', {
    staticClass: "details__total"
  }, [_c('span', {
    staticClass: "details__total-label"
  }, [_vm._v("VAT")]), _c('p', {
    staticClass: "details__total-value"
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(_vm._s(_vm.total23.vat) + " " + _vm._s(_vm.formData.currency.value))])])]), _c('div', {
    staticClass: "details__total"
  }, [_c('span', {
    staticClass: "details__total-label"
  }, [_vm._v("GROSS")]), _c('p', {
    staticClass: "details__total-value text-danger"
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(_vm._s(_vm.total23.gross) + " " + _vm._s(_vm.formData.currency.value))])])])], 1) : _vm._e()])], 1), _c('div', {
    staticClass: "document-box__form"
  }, [_c('div', {
    staticClass: "document-box__form-row"
  }, [_c('sygni-text-area', {
    attrs: {
      "disabled": _vm.cantEdit && !((_vm$document14 = _vm.document) !== null && _vm$document14 !== void 0 && (_vm$document14$action = _vm$document14.actions) !== null && _vm$document14$action !== void 0 && _vm$document14$action.includes('update-description')) || _vm.isUltimatelyFailed,
      "label": 'DESCRIPTION'
    },
    on: {
      "input": _vm.handleFieldChange
    },
    model: {
      value: _vm.formData.description.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.description, "value", $$v);
      },
      expression: "formData.description.value"
    }
  })], 1)]), _c('div', {
    staticClass: "document-box__form"
  }, [_c('sygni-container-title', {
    attrs: {
      "inline": true
    }
  }, [_vm._v("Attachments")]), (_vm$document15 = _vm.document) !== null && _vm$document15 !== void 0 && (_vm$document15$action = _vm$document15.actions) !== null && _vm$document15$action !== void 0 && _vm$document15$action.includes('add-attachment') ? _c('file-uploader', {
    attrs: {
      "supported-file-formats": _vm.supportedFileFormats,
      "dropAreaText": "Drag and drop to add your new document",
      "infoText": "File format: pdf, jpg, png / max 5mb",
      "uploadProgress": _vm.attachmentsUploadProgress,
      "multiple": true
    },
    on: {
      "input": function input($event) {
        return _vm.addFiles($event);
      }
    }
  }) : _vm._e(), _c('attachments-table', {
    attrs: {
      "tableItems": _vm.documentAttachments,
      "busy": _vm.isAttachmentsTableBusy,
      "showDelete": (_vm$document16 = _vm.document) === null || _vm$document16 === void 0 ? void 0 : (_vm$document16$action = _vm$document16.actions) === null || _vm$document16$action === void 0 ? void 0 : _vm$document16$action.includes('delete-attachment'),
      "showPagination": false
    },
    on: {
      "removeItem": _vm.removeAttachment
    }
  })], 1)]), _c('div', {
    staticClass: "document-box__column document-box__column--doc"
  }, [_c('preview-doc', {
    attrs: {
      "activeDocument": _vm.document,
      "enabledActions": (_vm$document17 = _vm.document) === null || _vm$document17 === void 0 ? void 0 : _vm$document17.actions,
      "contextData": _vm.contextData,
      "isUltimatelyFailed": _vm.isUltimatelyFailed,
      "selectedDocument": _vm.selectedDocument,
      "checkViewport": false,
      "showDocument": _vm.showDocument,
      "shouldSend": _vm.shouldSend,
      "sendToApprovalErrors": _vm.sendToApprovalErrors
    },
    on: {
      "approve": _vm.approveDocument,
      "changeFlag": _vm.changeFlagAction,
      "close": _vm.closeDocument,
      "download": _vm.downloadDocument,
      "edit": _vm.saveChanges,
      "next": _vm.nextDocumentHandler,
      "prev": _vm.prevDocumentHandler,
      "reject": _vm.enableRejectionModal,
      "send": _vm.sendDocument,
      "delete": _vm.deleteDocument,
      "openFlagManagementModal": _vm.enableFlagManagementModal,
      "generatePaymentPackage": function generatePaymentPackage($event) {
        return _vm.enableBankAccountModal();
      },
      "reportDocument": _vm.openReportModal,
      "requestGlChange": _vm.openChangeGlModal
    }
  })], 1)])])])])])]), _c('flags-management-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showFlagManagementModal && !_vm.showFlagAccessModal,
      expression: "showFlagManagementModal && !showFlagAccessModal"
    }],
    on: {
      "close": _vm.closeFlagManagementModal,
      "showFlagAccessModal": _vm.enableFlagAccessModal
    }
  }), _c('flag-access-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showFlagAccessModal,
      expression: "showFlagAccessModal"
    }],
    attrs: {
      "flag": _vm.sharingFlag
    },
    on: {
      "close": _vm.closeFlagAccessModal
    }
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "document-box__note-icon"
  }, [_c('span', [_vm._v("i")])]);
}]

export { render, staticRenderFns }